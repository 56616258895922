/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect} from 'react'
import {Button, Grid, makeStyles, Box, Typography, TextField, Divider} from '@material-ui/core'
import {Cancel} from '@material-ui/icons'
import {Editor} from 'react-draft-wysiwyg'
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {MDBSpinner} from 'mdb-react-ui-kit'
import DefaultImage from '../../assets/images/default-item.jpeg'
import {ArrowBack} from '@material-ui/icons'
import axios from 'axios'
import {toast} from 'react-toastify'
import {aws_url, base_url} from '../../config/config'
import {color, toastStyle} from '../../assets/css/commonStyle'
import {generateMap} from '../common/generateMap'

const NewEditDistrict = ({updateData, goBackCB, setOpenScreenCB, ...props}) => {
    const classes = useStyles()
    const [districtId, setDistrictId] = useState('')
    const [districtName, setDistrictName] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [buttonSpinner, setButtonSpinner] = useState(false)
    const [session, setSession] = useState(true)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const errorMessage = 'Invalid image type. Please try again.'

    useEffect(() => {
        if (updateData !== undefined) {
            setDistrictId(updateData._id)
            setDistrictName(updateData.name)
            setLatitude(updateData.latitude)
            setLongitude(updateData.longitude)
            if(updateData.content) {
                setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(updateData.content))))
            }
            generateMap(updateData.longitude, updateData.latitude, setLongitude, setLatitude, updateData)
        } else {
            if(props.store !== undefined  ){
                setLongitude(props.store.lngLat[0])
                setLatitude(props.store.lngLat[1])
                generateMap(props.store.lngLat[0], props.store.lngLat[1], setLongitude, setLatitude)
            } else {
            	generateMap(-97.51573401818237, 35.473085788128714, setLongitude, setLatitude)
            }
        }
    }, [updateData])

    const updateDistrict = async () => {
        const convertedContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        if (districtName.length === 0) {
            toast.error('District name is required.', {
                position: toastStyle.position,
                autoClose: toastStyle.closeDuration
            })
        } else {
            setButtonSpinner(true)
            await axios
                .post(
                    `${base_url}/admin/districts/update`,
                    {
                        id: districtId,
                        name: districtName,
                        latitude: latitude,
                        longitude: longitude,
                        content: convertedContent
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('auth_token')}`
                        }
                    }
                )
                .then((response) => {
                    if (response.data.success) {
                        toast.success('District updated successfully.', {
                            position: toastStyle.position,
                            autoClose: toastStyle.closeDuration
                        })
                        setButtonSpinner(false)
                        setOpenScreenCB(false)
                        goBackCB()
                    } else {
                        toast.error('Something went wrong. Please try again later.', {
                            position: toastStyle.position,
                            autoClose: toastStyle.closeDuration
                        })
                    }
                })
                .catch(function (error) {
                    toast.error('Something went wrong. Please try again later.', {
                        position: toastStyle.position,
                        autoClose: toastStyle.closeDuration
                    })
                })
        }
    }

    const addNewDistrict = async () => {
        let convertedContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        if (convertedContent === undefined) {
            convertedContent = ''
        }
        if (districtName.length === 0) {
            toast.error('District name is requied.', {
                position: toastStyle.position,
                autoClose: toastStyle.closeDuration
            })
        } else {
            setButtonSpinner(true)
            await axios
                .post(
                    `${base_url}/admin/districts/add`,
                    {
                        name: districtName,
                        latitude: latitude,
                        longitude: longitude,
                        content: convertedContent
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('auth_token')}`
                        }
                    }
                )
                .then((response) => {
                    if (response.data.success) {
                        toast.success('Disitrict added successfully.', {
                            position: toastStyle.position,
                            autoClose: toastStyle.closeDuration
                        })
                        setButtonSpinner(false)
                        setOpenScreenCB(false)
                        goBackCB()
                    } else {
                        toast.error('District already exist.', {
                            position: toastStyle.position,
                            autoClose: toastStyle.closeDuration
                        })
                    }
                    setButtonSpinner(false)
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    toast.error('Something went wrong. Please try again later.', {
                        position: toastStyle.position,
                        autoClose: toastStyle.closeDuration
                    })
                    return error
                })
        }
    }

    let wordCount = 0
    if (convertToRaw(editorState.getCurrentContent())?.blocks?.length > 0) {
        for (const block of convertToRaw(editorState.getCurrentContent())?.blocks) {
            wordCount += block.text.length
        }
    }

    return (
        <div className={classes.root}>
            <Grid item container className={classes.items} xs={12} sm={12} md={8} lg={8}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h4" className={classes.heading}>
                        New/Edit District
                    </Typography>
                </Grid>
                {/* <Box>
						<Button variant="outlined" className={classes.cancelButton} startIcon={<ArrowBack style={{fontSize: '14px'}} />} size="small" onClick={goBackCB}>
							Go back
						</Button>
					</Box> */}
                <Divider variant="fullWidth" className={classes.divider} />
                <Box className={classes.detailsContainer}>
                    <Typography variant="body1" className={classes.textLabel}>
                        District Name
                    </Typography>
                    <TextField type="text" value={districtName} size="small" className={classes.textField} variant="outlined" fullWidth onChange={(e) => setDistrictName(e.target.value)} />

                    <Typography variant="body1" className={classes.textLabel}>
                        District Preview
                    </Typography>
                    <Typography variant="body1" className={classes.descriptionLabel}>
                        Click anywhere in the map to place marker at that location. Drag to move it.
                    </Typography>
                    <Box className={classes.mapImageBox}>
                        <div className={classes.mapImage} id="mapContainer"></div>
                        <pre id="coordinates" className={classes.coordinates}></pre>
                    </Box>

                    <Grid item container spacing={2} xs={12} sm={12} md={12} lg={12} variant="body1" className={classes.locationContainer}>
                        <Grid item container xs={12} sm={12} md={4} lg={4}>
                            <Grid item className={classes.textLeft} xs={12} sm={12} md={12} lg={12}>
                                Longitude
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField type="text" value={longitude} size="small" className={classes.smallTextField} variant="outlined" fullWidth onChange={(e) => setLongitude(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={12} md={4} lg={4}>
                            <Grid item className={classes.textLeft} xs={12} sm={12} md={12} lg={12}>
                                Latitude
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField type="text" value={latitude} size="small" className={classes.smallTextField} variant="outlined" fullWidth onChange={(e) => setLatitude(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid className={classes.btnAlign} item xs={12} sm={12} md={4} lg={4}>
                            <Button variant="contained" className={classes.setLocationButton} size="small" onClick={() => generateMap(longitude, latitude)}>
                                Set Location
                            </Button>
                        </Grid>
                    </Grid>

                    <Typography variant="body1" className={classes.textLabel}>
                        District Information Content
                    </Typography>
                    <Typography variant="body1" className={classes.additionalContentDescription}>
                        {wordCount <= 1500 ? wordCount : 1500} of 1500 words
                    </Typography>
                    <Editor editorState={editorState} value={editorState} onEditorStateChange={setEditorState} wrapperClassName={classes.wrapperClass} editorClassName={classes.editorClass} toolbarClassName={classes.toolbarClass} />


                    <div className={classes.buttonContainer}>
                        <Button variant="outlined" className={classes.cancelButton} size="small" onClick={goBackCB}>
                            Cancel
                        </Button>
                        {buttonSpinner ? (
                            <Button variant="contained" className={classes.saveButton} size="small">
                                <MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner>
                            </Button>
                        ) : updateData !== undefined ? (
                            <Button variant="contained" className={classes.saveButton} size="small" onClick={updateDistrict}>
                                Save Changes
                            </Button>
                        ) : (
                            <Button variant="contained" className={classes.saveButton} size="small" onClick={addNewDistrict}>
                                Save Changes
                            </Button>
                        )}
                    </div>
                </Box>
            </Grid>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: `${color.pagesBg} !important`,
        display: 'flex',
        justifyContent: 'center'
    },
    detailsContainer: {
        backgroundColor: '#fff',
        borderRadius: '12px',
        padding: '32px',
        gap: '40px'
    },
    heading: {
        margin: '1rem 0',
        fontFamily: 'ZillaSlab-Bold'
    },
    textField: {
        color: color.gray,
        borderRadius: '12px !important',
        padding: '8px 16px',
        gap: '12px',
        border: `2px solid ${color.newBorder}`,
        flex: 'none',
        order: 1,
        alignSelf: 'stretch',
        flexGrow: 0
    },
    smallTextField: {
        color: color.gray,
        borderRadius: '12px !important',
        padding: '8px 16px',
        gap: '12px',
        border: `2px solid ${color.newBorder}`,
        flex: 'none',
        order: 1,
        alignSelf: 'stretch',
        flexGrow: 0
    },
    uploadFile: {opacity: 0},
    inputLabel: {
        backgroundColor: color.primary,
        color: color.white,
        borderRadius: '4px',
        marginTop: '1rem',
        fontSize: '14px',
        padding: '8px 20px',
        letterSpacing: '1px',
        '&:hover': {
            backgroundColor: color.hoverPrimary
        }
    },
    setLocationButton: {
        color: color.white,
        backgroundColor: color.primary,
        fontWeight: '400',
        fontSize: '14px',
        letterSpacing: '1px',
        textTransform: 'capitalize',
        padding: '7px 20px',
        '&:hover': {
            backgroundColor: color.hoverPrimary,
            color: color.white
        }
    },
    locationContainer: {
        marginTop: '1rem',
        textAlign: 'center'
    },
    textLabel: {
        marginTop: '1rem',
        marginBottom: '1rem',
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '15px',
        color: '#414A52'
    },
    wrapperClass: {
        border: `1px solid ${color.newBorder}`,
        borderRadius: '0.5rem',
        padding: '0.2rem'
    },
    toolbarClass: {
        display: 'none',
        border: `1px solid  ${color.newBorder}`
    },
    editorClass: {
        padding: '0.1rem',
        color: color.darkGray,
        height: '12rem'
    },
    mapImageBox: {
        height: '23rem',
        borderRadius: '1rem'
    },
    mapImage: {
        width: '100%',
        height: '100%',
        borderRadius: '1rem',
        border: 'none'
    },
    featuredImageBox: {
        height: '16rem',
        borderRadius: '0.6rem'
    },
    featuredImage: {
        borderRadius: '0.6rem',
        border: '1.5px solid gray',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    additionalImageBox: {
        borderRadius: '0.4rem'
    },
    additionalImage: {
        width: '100%',
        height: '8rem',
        borderRadius: '0.4rem',
        border: '1px solid gray',
        objectFit: 'cover'
    },
    deletebutton: {
        backgroundColor: 'transparent',
        color: color.darkGray,
        position: 'absolute',
        marginLeft: '-3.6rem',
        marginTop: '-0.2rem',
        width: '0.1rem',
        border: 'none'
    },
    delete: {
        width: '100%',
        height: '100%',
        transform: 'scale(0.6)',
        '&:hover': {
            color: color.gray
        }
    },
    deletebuttonSmall: {
        backgroundColor: 'transparent',
        color: color.darkGray,
        position: 'absolute',
        marginLeft: '-3.6rem',
        marginTop: '-0.8rem',
        width: '0.1rem',
        border: 'none'
    },
    deleteSmall: {
        width: '100%',
        height: '100%',
        transform: 'scale(0.4)',
        '&:hover': {
            color: color.gray
        }
    },
    divider: {
        margin: '10px 0px 0px 0px'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    cancelButton: {
        width: '140px',
        backgroundColor: '#F0F0F0',
        color: color.black,
        outline: color.gray,
        fontWeight: '400',
        fontSize: '14px',
        textTransform: 'capitalize',
        margin: '8px',
        padding: '8px 20px',
        letterSpacing: '1px',
        border: 'none',
        borderRadius: '1rem',
        '&:hover': {
            backgroundColor: color.lightGray
        }
    },
    saveButton: {
        width: '150px',
        color: color.white,
        backgroundColor: color.buttonBg,
        fontWeight: '400',
        fontSize: '14px',
        textTransform: 'capitalize',
        letterSpacing: '1px',
        margin: '8px',
        padding: '8px 20px',
        border: 'none',
        borderRadius: '1rem',
        '&:hover': {
            backgroundColor: color.hoverButtonBg
        }
    },
    textLeft: {
        textAlign: 'left',
        marginTop: '1rem',
        marginBottom: '1rem',
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '15px',
        color: '#414A52'
    },
    btnAlign: {
        display: 'flex',
        alignItems: 'end'
    },
    spinnerStyle: {
        color: color.white,
        width: '24px',
        height: '24px'
    },
    additionalContentDescription: {
        marginTop: '1rem',
        marginBottom: '1rem',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#999A9C'
    },
    descriptionLabel: {
        marginTop: '1rem',
        marginBottom: '1rem',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#999A9C'
    }
}))

export default NewEditDistrict
