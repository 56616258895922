/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect} from 'react'
import {Button, makeStyles, Modal, Card, CardHeader, CardContent, CardActions, IconButton, TextField, Divider} from '@material-ui/core'
import {Cancel} from '@material-ui/icons'
import {color, toastStyle} from '../../assets/css/commonStyle'
import {toast} from 'react-toastify'
import axios from 'axios'
import {base_url} from '../../config/config'
import {connect} from 'react-redux'
import {storeLogin} from '../../redux/actions/actions'
import {validate} from './validate'
import SessionExpiredModal from './SessionExpireModal'

const UpdateModal = ({openCB, closeCB, updateDataCB, ...props}) => {
	const classes = useStyles()
	const [userId, setUserId] = useState('')
	const [username, setUsername] = useState('')
	const [email, setEmail] = useState('')
	const [oldPassword, setOldPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [reNewPassword, setReNewPassword] = useState('')
	const [enabled, setEnabled] = useState(false)
	const [changePassword, setChangePassword] = useState(false)
	const [session, setSession] = useState(true)

	useEffect(() => {
		if (updateDataCB !== null && updateDataCB !== undefined && updateDataCB !== '') {
			setUserId(updateDataCB._id)
			setUsername(updateDataCB.name ? updateDataCB.name : '')
			setEmail(updateDataCB.email)
			setEnabled(updateDataCB.enabled)
		}
	}, [updateDataCB])

	const validateEmail = (email) => {
		let validFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		if (email.match(validFormat)) return true
		else return false
	}

	const updateProfile = async () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			if (username === '') {
				toast.error('Username is required', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else if (email === '') {
				toast.error('Email is required', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else if (!validateEmail(email)) {
				toast.error('Invalid email. Please try again', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else if (newPassword !== reNewPassword) {
				toast.error('New password and confirm new password do not match', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else if (oldPassword !== '' && oldPassword !== undefined && newPassword === '') {
				toast.error('New password is required', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else if (newPassword !== '' && newPassword.length < 8) {
				toast.error('New password length must not be less than 8 characters', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else {
				await doUpdateProfile(userId, username, email, newPassword, oldPassword)
			}
		}
	}

	const doUpdateProfile = async (userId, username, email, newPassword, oldPassword) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			return await axios
				.post(
					`${base_url}/admin/update_user`,
					{
						id: userId,
						name: username,
						email: email,
						newPassword: newPassword,
						oldPassword: oldPassword
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('auth_token')}`
						}
					}
				)
				.then((response) => {
					if (response.data.success) {
						toast.success('User profile updated successfully', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						setChangePassword(false)
						closeCB()
					} else if (response.data.message === 'Password not matched') {
						toast.error('Old password does not match', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
					} else {
						toast.error('Something went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					console.error(error)
				})
		}
	}
	return (
		<div className={classes.modal}>
			{session ? (
				<Modal open={openCB} onClose={closeCB} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
					<div className={classes.paper}>
						<Card>
							<CardHeader
								title="Update User"
								className={classes.cardHead}
								action={
									<IconButton aria-label="settings">
										<Cancel onClick={closeCB} />
									</IconButton>
								}
							/>
							<CardContent>
								{username !== undefined && (
									<>
										<TextField type="text" size="small" className={classes.textField} label="Username" variant="outlined" value={username} fullWidth onChange={(e) => setUsername(e.target.value)} />
										<Divider variant="fullWidth" className={classes.divider} />
									</>
								)}
								{email !== undefined && (
									<>
										<TextField type="text" size="small" className={classes.textField} label="Email" variant="outlined" value={email} fullWidth onChange={(e) => setEmail(e.target.value)} />
									</>
								)}
								{changePassword === false ? (
									<Button
										variant="outlined"
										className={classes.changePassButton}
										size="small"
										onClick={() => {
											setChangePassword(true)
										}}>
										Change Password
									</Button>
								) : (
									<div>
										<Divider variant="fullWidth" className={classes.divider} />
										<TextField type="text" size="small" className={classes.textField} label="Enter Old Password" variant="outlined" value={oldPassword} minlength="8" fullWidth onChange={(e) => setOldPassword(e.target.value)} />
										<Divider variant="fullWidth" className={classes.divider} />
										<TextField type="text" size="small" className={classes.textField} label="Enter New Password" variant="outlined" value={newPassword} minlength="8" fullWidth onChange={(e) => setNewPassword(e.target.value)} />
										<Divider variant="fullWidth" className={classes.divider} />
										<TextField
											type="text"
											size="small"
											className={classes.textField}
											label="Confirm New Password"
											variant="outlined"
											value={reNewPassword}
											minlength="8"
											fullWidth
											onChange={(e) => setReNewPassword(e.target.value)}
										/>
										<Divider variant="fullWidth" className={classes.divider} />
									</div>
								)}
							</CardContent>
							<CardActions className={classes.cardActions}>
								<Button variant="outlined" className={classes.cancelButton} size="small" onClick={closeCB}>
									Cancel
								</Button>
								<Button variant="contained" className={classes.updateButton} size="small" onClick={updateProfile}>
									Update user
								</Button>
							</CardActions>
						</Card>
					</div>
				</Modal>
			) : (
				<SessionExpiredModal closeCB={() => setSession(true)} />
			)}
		</div>
	)
}

const useStyles = makeStyles((theme) => ({
	paper: {
		textAlign: 'left',
		color: color.black,
		width: '40%',
		position: 'absolute',
		left: '0',
		right: '0',
		margin: 'auto',
		borderRadius: '5px',
		marginTop: '10px',
		[theme.breakpoints.down('md')]: {
			width: '80%'
		},
		[theme.breakpoints.up('md')]: {
			width: '40%'
		}
	},
	modal: {},
	textField: {
		marginTop: '15px',
		border: `1px solid ${color.lightGray} !important`,
		borderRadius: '5px !important',
		backgroundColor: color.lightGray
	},
	cardHead: {
		backgroundColor: color.navBarBg,
		color: color.white
	},
	cardActions: {
		// borderTop: `1px solid ${color.lightGray}`,
		padding: '11px 10px 15px 10px',
		justifyContent: 'flex-end'
	},
	label: {
		marginTop: '20px',
		color: color.gray,
		textAlign: 'left',
		marginLeft: '4px'
	},
	divider: {
		margin: '10px 0px 0px 0px'
	},
	changePassButton: {
		marginTop: '1rem',
		color: color.gray,
		outline: color.gray,
		fontWeight: '400',
		fontSize: '11px',
		letterSpacing: '1px',
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: color.gray,
			color: color.white
		}
	},
	cancelButton: {
		backgroundColor: color.cancel,
		color: color.black,
		outline: color.gray,
		fontWeight: '400',
		fontSize: '11px',
		letterSpacing: '1px',
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: color.hoverCancel
		}
	},
	updateButton: {
		color: color.white,
		backgroundColor: color.buttonBg,
		fontWeight: '400',
		fontSize: '11px',
		letterSpacing: '1px',
		textTransform: 'capitalize',
		margin: '8px',
		'&:hover': {
			backgroundColor: color.hoverButtonBg
		}
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateModal)
