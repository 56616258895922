/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {makeStyles, Typography, Avatar, Button, Box, Grid, Select} from '@material-ui/core'
import {AddCircle, Edit} from '@material-ui/icons'
import {toast} from 'react-toastify'
import {MDBDataTableV5} from 'mdbreact'
import {MDBSpinner} from 'mdb-react-ui-kit'
import {color, toastStyle} from '../assets/css/commonStyle'
import Pagination from '../components/common/Pagination'
import {base_url, aws_url} from '../config/config'
import AddAssetModal from '../components/common/AddSettingsModal'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
import {connect} from 'react-redux'
import {storeLogin} from '../redux/actions/actions'
import {validate} from '../components/common/validate'
import SessionExpiredModal from '../components/common/SessionExpireModal'

const AssetCategories = (props) => {
	const classes = useStyles()

	const [updateData, setUpdateData] = useState(undefined)
	const [updateId, setUpdateId] = useState('')
	const [openModal, setOpenModal] = useState(false)

	const [name, setName] = useState('')
	const [icon, setIcon] = useState('')

	const [active, setActive] = useState(false)
	const [buttonSpinner, setButtonSpinner] = useState(false)

	const [currentPage, setCurrentPage] = useState(1)
	const [length, setLength] = useState(0)
	const [spinner, setSpinner] = useState(false)
	const [session, setSession] = useState(true)

	const [categories, setCategories] = useState({
		columns: [
			{
				label: 'Name',
				field: 'name',
				width: 270,
				attributes: {'aria-controls': 'DataTable', 'aria-label': 'Name'}
			},
			{
				label: 'Icon',
				field: 'file'
			},
			{label: 'Enabled', field: 'enabled', type: 'checkbox'},
			{label: '', field: 'edit', width: 200, sort: String('disabled')}
		],
		rows: []
	})

	useEffect(() => {
		viewCategories()
	}, [])

	const handleEdit = (data) => {
		setUpdateData(data)
		setUpdateId(data._id)
		setName(data.name)
		setIcon(data.icon)
		setActive(data.enabled)
		setOpenModal(true)
	}

	const handleAddNew = () => {
		setOpenModal(true)
	}

	const cancel = () => {
		setOpenModal(false)
		setUpdateData(undefined)
	}

	function getData(page) {
		viewCategories(page)
		setCurrentPage(page)
	}

	const viewCategories = async () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			setSpinner(true)
			await axios
				.post(`${base_url}/admin/settings/assets/view_categories`)
				.then((response) => {
					if (response.data.success) {
						setSpinner(false)
						setLength(response.data.data.categories.length)
						setCategories((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						response.data.data.categories.map((data) => {
							createTable(data)
						})
					} else {
						setSpinner(false)
					}
				})
				.catch(function (error) {
					setSpinner(false)

					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					return error
				})
		}
	}

	const updateAsset = async (data) => {
		if (validate()) {
			if (data.name.length === 0) {
				toast.error('Asset category name is required.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else {
				setButtonSpinner(true)
				await axios
					.post(`${base_url}/admin/settings/assets/update`, {
						id: updateId,
						name: data.name,
						icon: data.icon,
						keywords: data.keywords,
						enabled: data.active
					})
					.then((response) => {
						if (response.data.success) {
							toast.success('Asset category updated successfully.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
							setOpenModal(false)
							setButtonSpinner(false)
							viewCategories()
						} else {
							toast.error('Something went wrong. Please try again later', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
						}
					})
					.catch(function (error) {
						toast.error('Something went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						return error
					})
			}
		} else {
			props.loginHandler(false)
			setSession(false)
		}
	}

	const handleEnable = async (row) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			await axios
				.post(`${base_url}/admin/settings/assets/update`, {
					...row,
					id: row._id,
					enabled: !row.enabled
				})
				.then((response) => {
					if (response.data.success) {
						toast.success('Enable status updated successfully.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						viewCategories()
					} else {
						toast.error('Soemthing went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					return error
				})
		}
	}

	const addAsset = async (data) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			if (data.name.length === 0) {
				toast.error('Asset category name is required.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				setOpenModal(true)
				setButtonSpinner(false)
			} else {
				setButtonSpinner(true)
				await axios
					.post(`${base_url}/admin/settings/assets/add`, {
						name: data.name,
						icon: data.icon,
						enabled: data.active
					})
					.then((response) => {
						if (response.data.success) {
							toast.success('Asset category added successfully.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
							setOpenModal(false)
							setButtonSpinner(false)
							viewCategories()
						} else if (response.data.message === 'Item already exist') {
							toast.error('Asset Category already exist', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
							setButtonSpinner(false)
						} else {
							toast.error('Asset Category could not be added. Please try again later', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
							setButtonSpinner(false)
						}
					})
					.catch(function (error) {
						toast.error('Something went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						setButtonSpinner(false)
						return error
					})
			}
		}
	}

	const createTable = (row) => {
		let _obj = {
			name: row.name,
			file: <img src={`${aws_url}/${row.icon}`} className={classes.tableImage2} />,
			enabled: (
				<label className="switch">
					<input type="checkbox" onChange={() => handleEnable(row)} checked={row.enabled} />
					<span className={`slider round`}></span>
				</label>
			),
			edit: (
				<Avatar variant="square" className={classes.editButton}>
					<Edit
						className="editIcon"
						onClick={() => {
							handleEdit(row)
						}}
					/>
				</Avatar>
			)
		}
		setCategories((preValue) => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	const handleCloseSessionModal = () => {
		viewCategories()
		setSession(true)
	}

	return (
		<div className={classes.root}>
			{session ? (
				<div>
					{openModal === false ? (
						<div className={classes.contentContainer}>
							<Grid container>
								<Grid item xs={12} sm={12} md={11} lg={11}>
									<Typography variant="body1" className={classes.tableHeading}>
										Live Asset Categories
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={1} lg={1}>
									<Button variant="contained" className={classes.addButton} startIcon={<AddCircle style={{fontSize: '16px'}} className="addNewIconBlack" />} size="small" onClick={handleAddNew}>
										<Box style={{paddingTop: '1px'}}>Add</Box>
									</Button>
								</Grid>
							</Grid>
							{spinner ? (
								<div className={classes.Flex}>
									<MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner>
								</div>
							) : (
								<div id="assetcategories">
									<MDBDataTableV5 className={classes.table} hover entries={25} data={categories} fullPagination={false} paging={false} info={false} searchTop={false} searchBottom={false} />
									<Pagination viewCB={getData} len={length} page={currentPage} />
								</div>
							)}
						</div>
					) : (
						<AddAssetModal openCB={openModal} closeCB={cancel} modalTitle="Live Asset Category" updateData={updateData} addActionCB={addAsset} updateActionCB={updateAsset} buttonSpinner={buttonSpinner} callerScreen="asset_category" />
					)}
				</div>
			) : (
				<SessionExpiredModal closeCB={handleCloseSessionModal} />
			)}
		</div>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		height: '100%',
		flexGrow: 1,
		backgroundColor: color.pagesBg,
		padding: '2rem'
	},
	items: {},
	tableHeading: {
		marginTop: '1rem',
		fontSize: '24px',
		fontWeight: '700'
	},
	tableImage: {width: '2rem', borderRadius: '0.3rem', border: `1px solid ${color.primary}`},
	editButton: {
		backgroundColor: 'transparent',
		width: '30px',
		height: '30px',
		cursor: 'pointer',
		borderRadius: '0.15rem',
		'&:hover': {}
	},
	addButton: {
		position: 'relative',
		top: '20px',
		padding: '8px 20px',
		backgroundColor: color.cancelBtnBg,
		color: color.textColor,
		fontSize: '16px',
		fontWeight: '700',
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: color.hoverCancel
		}
	},
	spinnerStyle: {
		color: color.white,
		width: '12px',
		height: '12px'
	},
	contentContainer: {
		backgroundColor: color.white,
		borderRadius: '12px',
		padding: '32px',
		gap: '40px'
	},
	Flex: {
		display: 'flex',
		justifyContent: 'center'
	},
	tableImage2: {
		width: '32px',
		height: '32px'
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssetCategories)
