/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import moment from 'moment'
import {toast} from 'react-toastify'
import {MDBDataTableV5} from 'mdbreact'
import {MDBSpinner} from 'mdb-react-ui-kit'
import {AddCircle, Edit} from '@material-ui/icons'
import {color, toastStyle} from '../assets/css/commonStyle'
import {makeStyles, Typography, Avatar, Grid, Divider, Select, Box, Button} from '@material-ui/core'
import {base_url} from '../config/config'
import AddModal from '../components/common/AddModal'
import UpdateModal from '../components/common/UpdateModal'
import Pagination from '../components/common/Pagination'
import {connect} from 'react-redux'
import {storeLogin} from '../redux/actions/actions'
import {validate} from '../components/common/validate'
import SessionExpiredModal from '../components/common/SessionExpireModal'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('auth_token')}`

const Users = (props) => {
	const classes = useStyles()

	const [authToken, setAuthToken] = useState(props.store.userData.token)
	const [filter, setFilter] = useState('all')
	const [updateData, setUpdateData] = useState('')
	const [openUpdateModal, setOpenUpdateModal] = useState(false)
	const [openAddNewModal, setOpenAddNewModal] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [length, setLength] = useState(0)
	const [spinner, setSpinner] = useState(false)
	const [session, setSession] = useState(true)
	const [users, setUsers] = useState({
		columns: [
			{
				label: 'Name',
				field: 'name',
				width: 270,
				attributes: {'aria-controls': 'DataTable', 'aria-label': 'Name'}
			},
			{label: 'Email', field: 'email', width: 270},
			{label: 'Role', field: 'role', width: 270},
			{label: 'Last Active', field: 'last_active', width: 270},
			{label: 'Enabled', field: 'enabled', type: 'checkbox'},
			{label: 'Edit', field: 'edit', width: 200, sort: String('disabled')}
		],
		rows: []
	})

	useEffect(() => {
		if (props.store.userData.token) {
			setAuthToken(props.store.userData.token)
		}
	}, [props.store.userData.token])

	useEffect(() => {
		viewUsers(currentPage)
	}, [])

	function getData(page) {
		viewUsers(page)
		setCurrentPage(page)
	}

	const handleAddModalOpen = () => {
		setOpenAddNewModal(true)
		viewUsers()
	}

	const handleAddModalClose = () => {
		setOpenAddNewModal(false)
		setUpdateData('')
		viewUsers()
	}

	const handleUpdateModalOpen = (row) => {
		setUpdateData(row)
		setOpenUpdateModal(true)
	}

	const handleUpdateModalClose = () => {
		setOpenUpdateModal(false)
		setUpdateData('')
		viewUsers()
	}

	const handleFilter = (e) => {
		setFilter(e.target.value)
		let roleFilter = e.target.value
		viewUsers(currentPage, roleFilter)
	}

	const handleCloseSessionModal = () => {
		viewUsers()
		setSession(true)
	}

	const viewUsers = (page, role) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			setSpinner(true)
			axios
				.post(
					`${base_url}/admin/view_all_users`,
					{
						role: role,
						page: page
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('auth_token')}`
						}
					}
				)
				.then((response) => {
					if (response.data.success) {
						setSpinner(false)
						setLength(response.data.data.admin.length)
						setUsers((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						response.data.data.admin.map((data) => {
							createUserTable(data)
						})
					} else {
						setSpinner(false)
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later', {position: toastStyle.position, autoClose: toastStyle.closeDuration})
					setSpinner(false)
					return error
				})
		}
	}

	const createUserTable = (row) => {
		let dated = moment.utc(row.lastActive).format('YYYY/MM/DD')
		let role = row.role === 'admin' ? 'Administrator' : 'User'
		let _obj = {
			name: row.name,
			email: row.email,
			role: role,
			last_active: dated,
			enabled: (
				<label className="switch">
					<input type="checkbox" onChange={() => handleEnable(row)} checked={row.enabled} />
					<span className={`slider round`}></span>
				</label>
			),
			edit: (
				<Avatar variant="square" className={classes.editButton}>
					<Edit
						className="editIcon"
						onClick={() => {
							handleUpdateModalOpen(row)
						}}
					/>
				</Avatar>
			)
		}
		setUsers((preValue) => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	const handleEnable = (row) => {
		axios
			.post(
				`${base_url}/admin/update_user`,
				{
					...row,
					id: row._id,
					enabled: !row.enabled
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('auth_token')}`
					}
				}
			)
			.then((response) => {
				if (response.data.success) {
					toast.success('Enable status updated successfully.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					viewUsers()
				} else {
					toast.error('Soemthing went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				return error
			})
	}

	return (
		<div className={classes.root}>
			{session ? (
				<div>
					<Grid container>
						<Grid item xs={12} sm={12} md={11} lg={11}>
							<Typography variant="h4" className={classes.heading}>
								Manage Users
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={1} lg={1}>
							<Button variant="contained" onClick={handleAddModalOpen} className={classes.button} startIcon={<AddCircle className="addNewIcon" style={{fontSize: '14px'}} />}>
								<Box style={{paddingTop: '1px'}}>Add</Box>
							</Button>
						</Grid>
					</Grid>
					<Box>
						<Select
							name="role"
							id="role"
							MenuProps={{
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left'
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'left'
								},
								getContentAnchorEl: null
							}}
							variant="outlined"
							value={filter}
							className={classes.selectBox}
							onChange={handleFilter}>
							<option value="all" className={classes.option}>
								All Roles
							</option>
							<option value="admin" className={classes.option}>
								Administrator
							</option>
							<option value="user" className={classes.option}>
								User
							</option>
						</Select>
					</Box>
					<Divider variant="fullWidth" className={classes.divider} />
					<AddModal openCB={openAddNewModal} closeCB={handleAddModalClose} />
					{updateData && <UpdateModal openCB={openUpdateModal} closeCB={handleUpdateModalClose} updateDataCB={updateData} />}
					{spinner ? (
						<div className={classes.Flex}>
							<MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner>
						</div>
					) : (
						<>
							<MDBDataTableV5 className={classes.table} hover entries={25} data={users} fullPagination={false} paging={false} info={false} searchTop={false} searchBottom={false} />
							<Pagination viewCB={getData} len={length} page={currentPage} />
						</>
					)}
				</div>
			) : (
				<SessionExpiredModal closeCB={handleCloseSessionModal} />
			)}
		</div>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		height: '100%',
		flexGrow: 1,
		backgroundColor: color.pagesBg,
		padding: '2rem'
	},
	button: {
		backgroundColor: color.buttonBg,
		color: color.white,
		textTransform: 'capitalize',
		fontSize: '14px',
		margin: '8px',
		padding: '8px 20px',
		'&:hover': {
			backgroundColor: color.hoverButtonBg
		}
	},
	selectBox: {
		width: '200px',
		fontSize: '11px',
		position: 'relative',
		left: '8px',
		top: '12px',
		height: '2.6rem',
		border: `1px solid ${color.lightGray} !important`,
		borderRadius: '12px !important',
		backgroundColor: color.ultraLightGreen,
		cursor: 'pointer',
		paddingLeft: '4px',
		paddingRight: '4px'
	},
	option: {
		cursor: 'pointer',
		fontSize: '11px',
		padding: '3px'
	},
	heading: {
		fontWeight: '600',
		fontSize: '36px',
		color: color.black
	},
	divider: {
		margin: '20px 0px 20px 0px'
	},
	editButton: {
		backgroundColor: 'transparent',
		width: '30px',
		height: '30px',
		cursor: 'pointer',
		borderRadius: '0.15rem'
	},
	spinnerStyle: {
		color: color.seaGreen,
		width: '60px',
		height: '60px',
		marginTop: '4%'
	},
	Flex: {
		display: 'flex',
		justifyContent: 'center'
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
