/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect} from 'react'
import {BrowserRouter as Router, Route, NavLink, Link} from 'react-router-dom'
import {Drawer, Box, List, ListItem, AppBar, MenuItem, Divider, Toolbar, IconButton, Menu} from '@material-ui/core'
import {BarChart, Settings, Image, People, Map, Room, MenuOpen, Cancel, Search, History, Person} from '@material-ui/icons'
import clsx from 'clsx'
import axios from 'axios'
import {makeStyles} from '@material-ui/core/styles'
import {color} from '../../assets/css/commonStyle'
import {connect} from 'react-redux'
import {storeLogin, storeUser, parentPageActive} from '../../redux/actions/actions'
import Home from '../../pages/DashBoard'
import Trails from '../../pages/Trails'
import Destinations from '../../pages/Destinations'
import Districts from '../../pages/Districts'
import LiveAssets from '../../pages/LiveAssets'
import Searching from '../../pages/Search'
import Users from '../../pages/Users'
import SettingsPage from '../../pages/SettingsPage'
import Login from '../../pages/Login'
import ForgotPassword from '../../pages/ForgotPassword'
import UpdateModal from './UpdateModal'
import logoImg from '../../assets/images/logo.png'
import HistoryTab from '../../pages/History'
import {base_url} from '../../config/config'
import {validate} from './validate'

// const drawerWidth = 97
const drawerWidth = 293

const LeftNavBar = (props) => {
	const classes = useStyles()
	const [open] = useState(false)
	const [isLogin, setIsLogin] = useState(false)
	const [routeName, setRouteName] = useState('')
	const [updateData, setUpdateData] = useState('')
	const [user, setUser] = useState(null)
	const [openUpdateModal, setOpenUpdateModal] = useState(false)
	const [anchorElUser, setAnchorElUser] = React.useState(null)
	const [leftMenu, setLeftMenu] = useState(false)
	const [sessionExpired, setSessionExpired] = useState(false)

	useEffect(() => {
		window.addEventListener('resize', () => {
			const myWidth = window.innerWidth
			if (myWidth <= 767) {
				closeLeftMenu()
			}
			if (myWidth > 767) {
				openLeftMenu(myWidth)
			}
		})
	}, [window])

	useEffect(() => {
		if (props.store.userData.admin) {
			setIsLogin(props.store.isLogin)
			setUser(props.store.userData.admin)
		}
	}, [props.store.userData])

	useEffect(() => {
		setRouteName(window.location.pathname)
		if (localStorage.getItem('auth_token')) {
			doAuthenticate()
		}
	}, [])

	useEffect(() => {
		if (props.store.isLogin) {
			setTimeout(drawerAction, 3000)
		}
	}, [props])

	const drawerAction = () => {
		const myWidth = window.innerWidth
		myWidth <= 767 ? closeLeftMenu() : ''
	}

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget)
	}

	const handleCloseUserMenu = () => {
		setAnchorElUser(null)
	}

	const handleProfile = () => {
		handleCloseUserMenu()
		setUpdateData(user)
		setOpenUpdateModal(true)
	}

	const handleLogout = () => {
		handleCloseUserMenu()
		props.loginHandler(false)
		handleRoute('/login')
		window.location.reload(false)
		localStorage.removeItem('auth_token')
		localStorage.clear()
	}

	const doLogin = () => {
		props.loginHandler(true)
	}

	const handleRoute = (route) => {
		setRouteName(route)
		if (isLogin !== true) {
			setRouteName('/login')
		}
	}

	const handleUpdateModalClose = () => {
		setOpenUpdateModal(false)
	}

	const gotoForgetPage = () => {
		setRouteName('/forgot_password')
	}

	const openLeftMenu = (width) => {
		setLeftMenu(true)
		if (width > 767) {
			document.getElementById('drawer').style.position = 'relative'
		} else {
			document.getElementById('drawer').style.position = 'absolute'
		}
		document.getElementById('drawer').style.display = 'block'
	}

	const closeLeftMenu = () => {
		setLeftMenu(false)
		document.getElementById('drawer').style.position = 'absolute'
		document.getElementById('drawer').style.display = 'none'
	}

	const doAuthenticate = async () => {
		await axios
			.post(`${base_url}/admin/authenticate`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('auth_token')}`
				}
			})
			.then((response) => {
				if (response.data.success) {
					setUser(response.data.data.admin)
					props.onLoginSuccess(response.data.data.admin)
					setIsLogin(true)
				} else {
					localStorage.setItem('auth_token', '')
					props.onLoginSuccess('')
					setSessionExpired(true)
					isLogin(false)
				}
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	return (
		<Router>
			<div className={classes.root}>
				{isLogin && (
					<Box sx={{display: 'flex'}}>
						<AppBar
							id="header"
							className={classes.appbarsetting}
							position="fixed"
							sx={{
								width: `calc(100% - ${drawerWidth}px)`,
								ml: ` ${drawerWidth}px`
							}}>
							<Toolbar>
								{window.innerWidth <= 767 ? (
									<div>{leftMenu === true ? <Cancel className={`${classes.menubarClose} menuBar`} onClick={closeLeftMenu} /> : <MenuOpen className={`${classes.MenuBarIcon} menuBar`} onClick={openLeftMenu} />}</div>
								) : (
									<span></span>
								)}
								<Box sx={{flexGrow: 0}}>
									<IconButton onClick={handleOpenUserMenu} className={classes.iconButton} sx={{p: 0}}>
										<Person className="profileIcon" />
										{user ? user.name : ''}
									</IconButton>
									<Menu
										className={classes.selectBox}
										sx={{mt: '45px'}}
										id="menu-appbar"
										anchorEl={anchorElUser}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right'
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right'
										}}
										open={Boolean(anchorElUser)}
										onClose={handleCloseUserMenu}>
										<MenuItem onClick={() => handleProfile(user)} className={classes.selectOptions}>
											<Link to="/" className={classes.selectOptions}>
												Profile
											</Link>
										</MenuItem>
										<Divider variant="fullWidth" className={classes.divider} />
										<MenuItem onClick={handleLogout} className={classes.selectOptions}>
											<Link to="/login" className={classes.selectOptions}>
												Logout
											</Link>
										</MenuItem>
									</Menu>
								</Box>
							</Toolbar>
						</AppBar>
						<Drawer
							id="drawer"
							variant="permanent"
							className={clsx(classes.drawer, {[classes.drawerClose]: !open})}
							classes={{
								paper: clsx({
									[classes.drawerClose]: !open
								})
							}}
							sx={{
								width: drawerWidth,
								flexShrink: 0
							}}
							anchor="left">
							<Toolbar />
							<Divider />
							<List id="list" className={classes.list}>
								<div className={classes.logoImg}>
									<img src={logoImg} className={classes.logoImgTag} />
								</div>
								<NavLink
									exact
									to="/"
									activeClassName="active_class"
									onClick={() => {
										handleRoute('/')
										window.innerWidth <= 767 ? closeLeftMenu() : ''
									}}
									className={classes.link}>
									<div className={classes.listItem}>
										<ListItem className={classes.py_0}>
											<BarChart className={classes.navbaricon} />
											<Box className={classes.navItem}>Dashboard</Box>
										</ListItem>
									</div>
								</NavLink>
								<NavLink
									exact
									to="/trails"
									activeClassName="active_class"
									onClick={() => {
										handleRoute('/trails')
										window.innerWidth <= 767 ? closeLeftMenu() : ''
										props.parentPageHandler(true)
									}}
									className={classes.link}>
									<div className={classes.listItem}>
										<ListItem className={classes.py_0}>
											<Map className={classes.navbaricon} />
											<Box className={classes.navItem}>Trails</Box>
										</ListItem>
									</div>
								</NavLink>
								<NavLink
									exact
									to="/destinations"
									activeClassName="active_class"
									onClick={() => {
										handleRoute('/destinations')
										window.innerWidth <= 767 ? closeLeftMenu() : ''
										props.parentPageHandler(true)
									}}
									className={classes.link}>
									<div className={classes.listItem}>
										<ListItem className={classes.py_0}>
											<Room className={classes.navbaricon} />
											<Box className={classes.navItem}>Destinations</Box>
										</ListItem>
									</div>
								</NavLink>
								<NavLink
									exact
									to="/districts"
									activeClassName="active_class"
									onClick={() => {
										handleRoute('/districts')
										window.innerWidth <= 767 ? closeLeftMenu() : ''
										props.parentPageHandler(true)
									}}
									className={classes.link}>
									<div className={classes.listItem}>
										<ListItem className={classes.py_0}>
											<Room className={classes.navbaricon} />
											<Box className={classes.navItem}>Districts</Box>
										</ListItem>
									</div>
								</NavLink>
								<NavLink
									exact
									to="/live-assets"
									activeClassName="active_class"
									onClick={() => {
										handleRoute('/live-assets')
										window.innerWidth <= 767 ? closeLeftMenu() : ''
										props.parentPageHandler(true)
									}}
									className={classes.link}>
									<div className={classes.listItem}>
										<ListItem className={classes.py_0}>
											<Image className="liveAssetIcon" />
											<Box className={classes.navItem}>Live assets</Box>
										</ListItem>
									</div>
								</NavLink>
								<NavLink
									exact
									to="/settings"
									activeClassName="active_class"
									onClick={() => {
										handleRoute('/settings')
										window.innerWidth <= 767 ? closeLeftMenu() : ''
									}}
									className={classes.link}>
									<div className={classes.listItem}>
										<ListItem className={classes.py_0}>
											<Settings className={classes.navbaricon} />
											<Box className={classes.navItem}>Settings</Box>
										</ListItem>
									</div>
								</NavLink>
								<NavLink
									exact
									to="/search"
									activeClassName="active_class"
									onClick={() => {
										handleRoute('/search')
										window.innerWidth <= 767 ? closeLeftMenu() : ''
									}}
									className={classes.link}>
									<div className={classes.listItem}>
										<ListItem className={classes.py_0}>
											<Search className={classes.navbaricon} />
											<Box className={classes.navItem}>Search</Box>
										</ListItem>
									</div>
								</NavLink>
								{user.role === 'admin' && (
									<NavLink
										exact
										to="/users"
										activeClassName="active_class"
										className={classes.link}
										onClick={() => {
											handleRoute('/user')
											window.innerWidth <= 767 ? closeLeftMenu() : ''
										}}>
										<div className={classes.listItem}>
											<ListItem className={classes.py_0}>
												<People className="peopleIcon" />
												<Box className={classes.navItem}>Users</Box>
											</ListItem>
										</div>
									</NavLink>
								)}
								<NavLink
									exact
									to="/history"
									activeClassName="active_class"
									onClick={() => {
										handleRoute('/search')
										window.innerWidth <= 767 ? closeLeftMenu() : ''
									}}
									className={classes.link}>
									<div className={classes.listItem}>
										<ListItem className={classes.py_0}>
											<History className={classes.navbaricon} />
											<Box className={classes.navItem}>History</Box>
										</ListItem>
									</div>
								</NavLink>
							</List>
						</Drawer>
					</Box>
				)}
				<main className={classes.content}>
					{isLogin === true ? (
						<div>
							<Route exact path="/" component={Home}></Route>
							<Route exact path="/trails" component={() => <Trails />}></Route>
							<Route exact path="/destinations" component={Destinations}></Route>
							<Route exact path="/districts" component={Districts}></Route>
							<Route exact path="/live-assets" component={LiveAssets}></Route>
							<Route exact path="/settings" component={SettingsPage}></Route>
							<Route exact path="/search" component={Searching}></Route>
							<Route exact path="/users" component={Users}></Route>
							<Route exact path="/history" component={HistoryTab}></Route>
						</div>
					) : (
						<div>
							{routeName === '/forgot_password' ? (
								<Route exact path="/forgot_password" component={ForgotPassword}></Route>
							) : (
								<Route exact path={routeName} component={() => <Login loginFun={doLogin} gotoForgetPage={gotoForgetPage} />}></Route>
							)}
						</div>
					)}
				</main>
			</div>
			<UpdateModal openCB={openUpdateModal} closeCB={handleUpdateModalClose} updateDataCB={updateData} />
		</Router>
	)
}
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex'
	},
	logoImg: {
		padding: '4px',
		margin: '1.2rem 0'
	},
	logoImgTag: {
		width: '100%',
		height: '100%'
	},
	appbarsetting: {
		width: `calc(100% - ${293}px)`,
		backgroundColor: color.white,
		border: 'none',
		boxShadow: 'none',
		textAlign: 'left'
	},
	iconButton: {
		width: '200px',
		fontSize: '16px',
		borderRadius: '0.4rem !important',
		justifyContent: 'center',
		color: color.navBarBg
	},
	openLeftMenu: {},
	menuitemslist: {
		textAlign: 'left',
		float: 'right',
		display: 'flex',
		justifyContent: 'flex-end'
	},
	menuitem: {
		'&:hover': {
			backgroundColor: color.white
		}
	},
	selectBox: {
		width: '240px'
	},
	selectOptions: {
		width: '180px',
		cursor: 'pointer',
		marginLeft: '8px',
		marginRight: '8px',
		color: color.black,
		textAlign: 'left',
		justifyContent: 'left !important'
	},
	divider: {
		margin: '8px 0px 8px 0px'
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		zIndex: 4
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: 'hidden',
		width: '293px',
		[theme.breakpoints.up('sm')]: {
			width: 293
		},
		backgroundColor: color.navBarBg
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		paddingTop: '5rem',
		backgroundColor: color.pagesBg
	},
	listItem: {
		borderRadius: '12px',
		margin: '0.4rem 0.8rem',
		width: '229px',
		height: '52px',
		padding: '0.8rem 2rem',
		'&:hover': {
			backgroundColor: color.hoverNavbarBg,
			fontWeight: '700'
		}
	},
	navItem: {
		fontSize: '16px',
		color: color.white,
		marginLeft: '0.4rem',
		letterSpacing: '0.5px'
	},
	profileIcon: {
		fontSize: '30px',
		color: color.navBarBg
	},
	white: {
		fontSize: '30px',
		color: color.white,
		backgroundColor: color.navBarBg
	},
	py_0: {
		paddingBottom: '0px',
		paddingTop: '0px'
	},
	link: {
		textDecoration: 'none !important',
		backgroundColor: color.navBarBg,
		display: 'flex',
		justifyContent: 'center'
	},
	list: {
		position: 'absolute',
		color: color.white,
		padding: '0 0.8rem'
	},
	MenuBarIcon: {
		color: color.black,
		fontSize: '2rem',
		cursor: 'pointer'
	},
	navbaricon: {
		color: color.white
	},
	menubarClose: {
		backgroundColor: 'transparent',
		color: color.black
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data)),
	onLoginSuccess: (data) => dispatch(storeUser(data)),
	parentPageHandler: (data) => dispatch(parentPageActive(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavBar)
