/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import CloudIcon from '../svg/cloud_upload.svg'

export const color = {
	white: '#fff',
	black: '#2f3838',
	primary: '#54a7e0',
	secondary: '#e12d5a', //required
	red: '#f46a6a',
	lightGray: '#ececec',
	purple: '#e06749',
	lightPurple: '#ae3b1e',
	seaGreen: '#428d95',
	hoverSeaGreen: '#2d6167',
	golden: '#f59c1a',
	pink: '#ff5b57',
	gray: '#808080',
	darkGray: '#a9a9a9',
	lightGreen: '#90EE90',
	green: '#00FF00',
	darkGreen: '#0b565e',
	hoverSecondary: '#780000',
	hoverPrimary: '#3b8ec7',
	newBorder: '#DDDDDE',
	pagesBg: '#F0F0F0',
	navBarBg: '#046982',
	hoverNavbarBg: '#0391b4',
	buttonBg: '#00A850',
	hoverButtonBg: '#038842',
	ultraLightGreen: '#ECF8F2',
	cancelBtnBg: '#F0F0F0',
	textColor: '#414A52',
	hoverCancel: '#808080',
	tabColor: '#77787B'
}
export const toastStyle = {
	position: 'top-center',
	closeDuration: 1200
}
export const uploadLabel = async () => {
	const label = document.getElementsByClassName('sc-hKMtZM')[0]
	label.nextSibling.innerHTML = ''
	label.innerHTML = ''
	label.style.padding = '3rem'
	label.style.fontSize = '1rem'
	const iconImage = document.createElement('img')
	iconImage.style.position = 'absolute'
	iconImage.style.left = '50%'
	iconImage.src = CloudIcon
	iconImage.style.width = '24px'
	iconImage.style.height = '24px'
	iconImage.style.display = 'inline-flex'
	const txt = document.createElement('span')
	txt.innerHTML = 'Drop your files here or '
	txt.style.textDecoration = 'none'
	const spanEl = document.createElement('span')
	spanEl.style.width = '2rem'
	spanEl.style.color = '#00A850'
	spanEl.style.marginLeft = '0.3rem'
	spanEl.innerHTML = 'browse'
	spanEl.style.textDecoration = 'none'
	const breakRow = document.createElement('br')
	label.appendChild(iconImage)
	label.appendChild(breakRow)
	label.appendChild(txt)
	label.appendChild(spanEl)
	return label
}
