/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React from 'react'
import LeftNavBar from './components/common/LeftNavBar'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const App = () => {
	return (
		<>
			<LeftNavBar />
			<ToastContainer />
		</>
	)
}
export default App
