/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {makeStyles, Typography, Grid, Divider} from '@material-ui/core'
import Card from '../components/common/Card'
import {color} from '../assets/css/commonStyle'
import {base_url} from '../config/config'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('auth_token')}`
import {connect} from 'react-redux'
import {storeLogin} from '../redux/actions/actions'

const DashBoard = () => {
	const classes = useStyles()
	const [trails, setTrails] = useState(0)
	const [destinations, setDestinations] = useState(0)
	const [districts, setDistricts] = useState(0)
	const [live_assets, setLiveAssets] = useState(0)
	const [users, setUsers] = useState(0)
	const [legends, setLegends] = useState(0)
	const [links, setLinks] = useState(0)
	const [mapItems, setMapItems] = useState(0)
	const [settings, setSettings] = useState(0)

	useEffect(() => {
		getStats()
	}, [])

	const getStats = async () => {
		await axios
			.get(`${base_url}/admin/dashboard/stats`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('auth_token')}`
				}
			})
			.then((response) => {
				if (response.data.success) {
					setUsers(response.data.data.data.admins)
					setTrails(response.data.data.data.trails)
					setDistricts(response.data.data.data.districts)
					setDestinations(response.data.data.data.destinations)
					setLiveAssets(response.data.data.data.liveassets)
					setLegends(response.data.data.data.legends)
					setLinks(response.data.data.data.links)
					setMapItems(response.data.data.data.mapItems)
					setSettings(response.data.data.data.subsettings)
				}
			})
			.catch(function (error) {
				console.error('Something went wrong. Please try again later.')
				return error
			})
	}

	return (
		<div className={classes.root}>
			<Typography variant="h4" className={classes.heading}>
				OKC Dashboard
			</Typography>
			<Typography variant="body1" className={classes.para}>
				View Analytics
			</Typography>
			<Divider variant="fullWidth" className={classes.divider} />
			<Grid container spacing={4}>
				<Grid item xs={12} md={4} sm={6}>
					<Card cardName="Trails" cardData={trails} cardColor={color.primary} />
				</Grid>
				<Grid item xs={12} md={4} sm={6}>
					<Card cardName="Destinations" cardData={destinations} cardColor={color.secondary} />
				</Grid>
				<Grid item xs={12} md={4} sm={6}>
					<Card cardName="Districts" cardData={districts} cardColor={color.secondary} />
				</Grid>
				<Grid item xs={12} md={4} sm={6}>
					<Card cardName="Map Items" cardData={mapItems} cardColor={color.gray} />
				</Grid>
				<Grid item xs={12} md={4} sm={6}>
					<Card cardName="Users" cardData={users} cardColor={color.lightPurple} />
				</Grid>
				<Grid item xs={12} md={4} sm={6}>
					<Card cardName="Links" cardData={links} cardColor={color.black} />
				</Grid>
				<Grid item xs={12} md={4} sm={6}>
					<Card cardName="Live assets" cardData={live_assets} cardColor={color.seaGreen} />
				</Grid>
				<Grid item xs={12} md={4} sm={6}>
					<Card cardName="Legends" cardData={legends} cardColor={color.red} />
				</Grid>
				<Grid item xs={12} md={4} sm={6}>
					<Card cardName="Settings" cardData={settings} cardColor={color.golden} />
				</Grid>
			</Grid>
		</div>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		flexGrow: 1,
		backgroundColor: color.pagesBg,
		padding: '2rem'
	},
	divider: {
		margin: '15px 0px 30px 0px'
	},
	heading: {
		fontWeight: '600',
		fontSize: '36px',
		color: color.black
	},
	para: {
		fontSize: '13px',
		color: color.black
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard)
