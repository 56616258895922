/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {makeStyles, Grid, Typography, Avatar, Button, Divider, responsiveFontSizes, Box} from '@material-ui/core'
import {AddCircle, Edit} from '@material-ui/icons'
import {MDBDataTableV5} from 'mdbreact'
import {MDBSpinner} from 'mdb-react-ui-kit'
import {color, toastStyle} from '../assets/css/commonStyle'
import {toast} from 'react-toastify'
import {base_url} from '../config/config'
import {connect} from 'react-redux'
import {storeLogin} from '../redux/actions/actions'
import {validate} from '../components/common/validate'
import SessionExpiredModal from '../components/common/SessionExpireModal'
import AddAssetModal from "../components/common/AddSettingsModal";
import AddKeywordsModal from "../components/common/AddKeywordsModal";

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const Searche = (props) => {
	const classes = useStyles()
	const [filter, setFilter] = useState('no_category_selected')
	const [keyword, setKeyword] = useState('')
	const [categories, setCategories] = useState([])
	const [spinner, setSpinner] = useState(false)
	const [session, setSession] = useState(true)

	const [updateData, setUpdateData] = useState(undefined)
	const [updateId, setUpdateId] = useState('')
	const [openModal, setOpenModal] = useState(false)
	const [buttonSpinner, setButtonSpinner] = useState(false)

	const [keywords, setKeywords] = useState({
		columns: [
			{
				label: 'Alternate Searches',
				field: 'name',
				width: 270,
				attributes: {'aria-controls': 'DataTable', 'aria-label': 'Name'}
			},
			{label: 'Enabled', field: 'enabled', type: 'checkbox'},
			{label: '', field: 'edit', width: 200, sort: String('disabled')}
		],
		rows: []
	})

	useEffect(() => {
		viewKeywords()
	}, [])

	const handleCloseSessionModal = () => {
		viewKeywords()
		setSession(true)
	}

	const doGetSearchData = async () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			await axios
				.post(`${base_url}/admin/settings/search_data`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('auth_token')}`
					}
				})
				.then((response) => {
					if (response.data.success) {
						setCategories(response.data.data.categories)
						// viewKeywords()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					return error
				})
		}
	}

	const handleAddNew = () => {
		setOpenModal(true)
	}

	const cancel = () => {
		setOpenModal(false)
		setUpdateData(undefined)
	}


	const viewKeywords = async () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			setSpinner(true)
			await axios
				.get(`${base_url}/admin/keywords/view_keywords`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('auth_token')}`
					}
				})
				.then((response) => {
					if (response.data.success) {
						setSpinner(false)
						setKeywords((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						response.data.data.keywords.map((data) => {
							createTable(data)
						})
					} else setSpinner(false)
				})
				.catch(function (error) {
					setSpinner(false)
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					return error
				})
		}
	}

	const viewSearches = async (category) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			alert('todo')
			return
			await axios
				.post(
					`${base_url}/admin/search`,
					{category: category},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('auth_token')}`
						}
					}
				)
				.then((response) => {
					if (response.data.success) {
						// if (response.data.data.searches.length > 0) {
						if (!response.data.data.searches.keywords) {
							setKeywords(response.data.data.searches)
						} else {
							setKeywords(response.data.data.searches.keywords)
						}
						// }
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					return error
				})
		}
	}

	const handleEnable = (id, enabled) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			axios
				.post(
					`${base_url}/admin/search/update_keyword`,
					{
						id: id,
						enabled: !enabled
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('auth_token')}`
						}
					}
				)
				.then((response) => {
					if (response.data.success) {
						toast.success('Enable status updated successfully.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						setFilter('no_category_selected')
						viewKeywords()
					} else {
						toast.error('Soemthing went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					return error
				})
		}
	}

	const handleEdit = (data) => {
		setUpdateData(data);
		setUpdateId(data._id)
		setKeyword(data.keyword)
		setOpenModal(true)
	}

	const updatedKeyword = async (data) => {
		if (validate()) {
			if (data === 0) {
				toast.error('Asset category name is required.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else {
				setButtonSpinner(true)
				await axios
					.post(`${base_url}/admin/search/update_keyword`, {
						id: updateId,
						keyword: data.keyword,
						enabled: data.active
					})
					.then((response) => {
						if (response.data.success) {
							toast.success('keyword updated successfully.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
							setOpenModal(false)
							setButtonSpinner(false)
							viewKeywords()
						} else {
							toast.error('Something went wrong. Please try again later', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
						}
					})
					.catch(function (error) {
						toast.error('Something went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						return error
					})
			}
		} else {
			props.loginHandler(false)
			setSession(false)
		}
	}

	const doAddKeyword = async (keyword) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			if (keyword.length === 0) {
				toast.error('Keyword is required.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				setOpenModal(true)
				setButtonSpinner(false)
			} else {
				await axios
					.post(
						`${base_url}/admin/search/add_keyword`,
						{
							keyword: keyword,
							enabled: true
						},
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem('auth_token')}`
							}
						}
					)
					.then((response) => {
						if (response.data.success) {
							toast.success('Search keyword added successfully.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
							// setOpenModal(false)
							setOpenModal(false);
							viewKeywords();

						} else {
							toast.error('Search keyword already exist.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
						}
					})
					.catch(function (error) {
						setOpenModal(false)
						setButtonSpinner(false)
						return error
					})
			}
		}
	}

	const handleFilter = (e) => {
		setFilter(e.target.value)
		// viewKeywords(e.target.value)
	}

	const getAllKeywords = () => {
		let enabledSearchWords = []
		for (let i = 0; i < keywords.length; i++) {
			if (keywords[i]) {
				enabledSearchWords.push(keywords[i].keyword)
			}
		}
		return enabledSearchWords
	}

	const handleSaveSearch = async () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			alert('to do')
			return
			if (filter === 'no_category_selected') {
				toast.error('At least one asset category must be selected', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else {
				const currentWords = getAllKeywords()
				await axios
					.post(
						`${base_url}/admin/search/save_search`,
						{
							category: filter,
							keywords: currentWords,
							enabled: true
						},
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem('auth_token')}`
							}
						}
					)
					.then((response) => {
						if (response.data.success) {
							toast.success('Search keyword added successfully.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
							document.getElementById('textField').value = ''
							document.getElementById('textField').focus()
							setKeyword('')
							viewKeywords()
						} else if (response.data.message === 'No categories found') {
							toast.error('No asset categories found to add keywords. Please add asset category first.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
						} else if (response.data.message === 'Search already exist') {
							toast.error('Search already created. Please try again', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
						} else {
							toast.error('Could not create search. Please try again later', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
						}
					})
					.catch(function (error) {
						toast.error('Something went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						return error
					})
			}
		}
	}

	const createTable = (row) => {
		let _obj = {
			name: row.keyword,
			enabled: (
				<label className="switch">
					<input type="checkbox" onChange={() => handleEnable(row)} checked={row.enabled} />
					<span className={`slider round`}></span>
				</label>
			),
			edit: (
				<Avatar variant="square" className={classes.editButton}>
					<Edit
						className="editIcon"
						onClick={() => {
							handleEdit(row)
						}}
					/>
				</Avatar>
			)
		}
		setKeywords((preValue) => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	return (
		<div className={classes.root}>
			{session ? (
				<div>
					{openModal === false ? (
						<div className={classes.contentContainer}>
							<Grid container>
								<Grid item xs={12} sm={12} md={11} lg={11}>
									<Typography variant="body1" className={classes.tableHeading}>
										Search Management
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={1} lg={1}>
									<Button variant="contained" className={classes.addButton} startIcon={<AddCircle style={{fontSize: '16px'}} className="addNewIconBlack" />} size="small" onClick={handleAddNew}>
										<Box style={{paddingTop: '1px'}}>Add</Box>
									</Button>
								</Grid>
							</Grid>
						{spinner ? (
							<div className={classes.Flex}>
								<MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner>
							</div>
						) : (
							<div>

								<MDBDataTableV5 className={classes.table} hover entries={25} data={keywords} fullPagination={false} paging={false} info={false} searchTop={false} searchBottom={false} />
							</div>
						)}

						<Grid item className={classes.buttonContainer} xs={12} sm={12}>
							<Button variant="outlined" className={classes.cancelButton} size="small">
								Cancel
							</Button>
							<Button variant="contained" className={classes.addButton} size="small">
								<label className={classes.buttonLabel}>Save Changes</label>
							</Button>
						</Grid>
					</div>
						) : (
						<AddKeywordsModal openCB={openModal} closeCB={cancel} modalTitle="Search Keywords" updateData={updateData} addActionCB={doAddKeyword}  updateActionCB={updatedKeyword} buttonSpinner={buttonSpinner} />

						)}
				</div>
			) : (
				<SessionExpiredModal closeCB={handleCloseSessionModal} />
			)}
		</div>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		height: '100%',
		flexGrow: 1,
		backgroundColor: color.pagesBg,
		padding: '2rem'
	},
	tableHeading: {
		marginTop: '1rem',
		fontSize: '24px',
		fontWeight: '700'
	},
	heading: {
		fontWeight: '600',
		fontSize: '36px',
		color: color.black,
		marginTop: '1rem'
	},
	items: {},
	textField: {
		border: `1px solid ${color.lightGray} !important`,
		borderRadius: '5px !important',
		backgroundColor: color.lightGray,
		width: '100%'
	},
	divider: {
		margin: '20px 0px 20px 0px'
	},
	editButton: {
		backgroundColor: 'transparent',
		width: '30px',
		height: '30px',
		cursor: 'pointer'
	},
	icon: {
		fontSize: '22px !important'
	},
	spinnerStyle: {
		color: color.seaGreen,
		width: '60px',
		height: '60px',
		marginTop: '4%',
		marginLeft: '48%'
	},
	contentContainer: {
		marginTop: '2rem',
		backgroundColor: color.white,
		borderRadius: '12px',
		padding: '0 32px 32px 32px',
		gap: '40px'
	},
	Flex: {
		display: 'flex',
		justifyContent: 'center'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'end'
	},
	cancelButton: {
		width: '140px',
		backgroundColor: '#F0F0F0',
		color: color.black,
		outline: color.gray,
		fontWeight: '400',
		fontSize: '14px',
		textTransform: 'capitalize',
		margin: '8px',
		padding: '8px 20px',
		letterSpacing: '1px',
		border: 'none',
		borderRadius: '1rem',
		'&:hover': {
			backgroundColor: color.lightGray
		}
	},
	buttonLabel: {margin: 0},
	addButton: {
		width: '150px',
		color: color.white,
		backgroundColor: color.buttonBg,
		fontWeight: '400',
		fontSize: '14px',
		textTransform: 'capitalize',
		letterSpacing: '1px',
		margin: '8px',
		padding: '8px 20px',
		border: 'none',
		borderRadius: '1rem',
		'&:hover': {
			backgroundColor: color.hoverButtonBg
		}
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Searche)
