/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {makeStyles, Typography, Avatar, Grid, Divider, Box, Button} from '@material-ui/core'
import {color, toastStyle} from '../assets/css/commonStyle'
import {toast} from 'react-toastify'
import {AddCircle, Edit} from '@material-ui/icons'
import {MDBDataTableV5} from 'mdbreact'
import Pagination from '../components/common/Pagination'
import {MDBSpinner} from 'mdb-react-ui-kit'
import {base_url} from '../config/config'
import NewEditDistricts from '../components/updatePages/NewEditDistrict'
import {connect} from 'react-redux'
import {storeLogin, parentPageActive} from '../redux/actions/actions'
import {validate} from '../components/common/validate'
import SessionExpiredModal from '../components/common/SessionExpireModal'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const Districts = (props) => {
	const classes = useStyles()
	const [updateData, setUpdateData] = useState('')
	const [openScreen, setOpenScreen] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [length, setLength] = useState(0)
	const [spinner, setSpinner] = useState(false)
	const [session, setSession] = useState(true)
	const [districts, setDistricts] = useState({
		columns: [
			{
				label: 'Name',
				field: 'name',
				width: 270,
				attributes: {'aria-controls': 'DataTable', 'aria-label': 'Name'}
			},
			{
				label: 'Longitude',
				field: 'lon'
			},
			{
				label: 'Latitude',
				field: 'lat'
			},
			{label: 'Enabled', field: 'enabled', type: 'checkbox'},
			{label: 'Edit', field: 'edit', width: 200, sort: String('disabled')}
		],
		rows: []
	})

	useEffect(() => {
		viewDistricts(currentPage)
	}, [])

	const openNewPage = () => {
		setOpenScreen(true)
		props.parentPageHandler(false)
	}

	const openEditPage = (row) => {
		setUpdateData(row)
		setOpenScreen(true)
		props.parentPageHandler(false)
	}

	const goBack = () => {
		setOpenScreen(false)
		setUpdateData('')
		viewDistricts()
		props.parentPageHandler(true)
	}

	function getData(page) {
		viewDistricts(page)
		setCurrentPage(page)
	}

	const handleCloseSessionModal = () => {
		viewDistricts()
		setSession(true)
	}

	const viewDistricts = async () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			setSpinner(true)
			await axios
				.get(`${base_url}/admin/districts/view_districts`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('auth_token')}`
					}
				})
				.then((response) => {
					if (response.data.success) {
						setSpinner(false)
						setLength(response.data.data.districts.length)
						setDistricts((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						response.data.data.districts.map((data) => {
							createTable(data)
						})
					} else {
						setSpinner(false)
					}
				})
				.catch(function (error) {
					console.log(error);
					setSpinner(false)
					toast.error('Something went wrong in fetching districts data. Please try again later.', {position: toastStyle.position, autoClose: toastStyle.closeDuration})
					return error
				})
		}
	}

	const handleEnable = async (row) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			await axios
				.post(
					`${base_url}/admin/districts/update`,
					{
						...row,
						id: row._id,
						enabled: !row.enabled
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('auth_token')}`
						}
					}
				)
				.then((response) => {
					if (response.data.success) {
						toast.success('Enable status updated successfully.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						viewDistricts()
					} else {
						toast.error('Something went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					return error
				})
		}
	}
	const createTable = (row) => {
		let _obj = {
			name: row.name,
			lat: row.latitude,
			lon: row.longitude,
			enabled: (
				<label className="switch">
					<input type="checkbox" onChange={() => handleEnable(row)} checked={row.enabled} />
					<span className={`slider round`}></span>
				</label>
			),
			edit: (
				<Avatar variant="square" className={classes.editButton}>
					<Edit
						className="editIcon"
						onClick={() => {
							openEditPage(row)
						}}
					/>
				</Avatar>
			)
		}
		setDistricts((preValue) => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	return (
		<div>
			{session ? (
				<div>
					{props.store.initalPageActive === true ? (
						<div className={classes.root}>
							<Grid container>
								<Grid item xs={12} sm={12} md={11} lg={11}>
									<Typography variant="h4" className={classes.heading}>
										Districts
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={1} lg={1}>
									<Button variant="contained" onClick={openNewPage} className={classes.button} startIcon={<AddCircle className="addNewIcon" style={{fontSize: '14px'}} />}>
										<Box style={{paddingTop: '1px'}}>Add</Box>
									</Button>
								</Grid>
							</Grid>
							<Divider variant="fullWidth" className={classes.divider} />
							{spinner ? (
								<div className={classes.Flex}>
									<MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner>
								</div>
							) : (
								<div id="districts">
									<MDBDataTableV5 className={classes.table} hover entries={25} data={districts} fullPagination={false} paging={false} info={false} searchTop={false} searchBottom={false} />
									<Pagination viewCB={getData} len={length} page={currentPage} />
								</div>
							)}
						</div>
					) : (
						<div>
							{updateData !== '' && updateData.name !== undefined ? (
								<NewEditDistricts updateData={updateData} goBackCB={goBack} setOpenScreenCB={setOpenScreen} />
							) : (
								<NewEditDistricts goBackCB={goBack} setOpenScreenCB={setOpenScreen} />
							)}
						</div>
					)}
				</div>
			) : (
				<SessionExpiredModal closeCB={handleCloseSessionModal} />
			)}
		</div>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		height: '100%',
		flexGrow: 1,
		backgroundColor: color.pagesBg,
		padding: '2rem'
	},
	button: {
		backgroundColor: color.buttonBg,
		color: color.white,
		textTransform: 'capitalize',
		fontSize: '14px',
		margin: '8px',
		padding: '8px 20px',
		'&:hover': {
			backgroundColor: color.hoverButtonBg
		}
	},
	table: {
		marginTop: '20px'
	},
	heading: {
		fontWeight: '600',
		fontSize: '36px',
		color: color.black
	},
	divider: {
		margin: '20px 0px 20px 0px'
	},
	editButton: {
		backgroundColor: 'transparent',
		width: '30px',
		height: '30px',
		cursor: 'pointer',
		borderRadius: '0.15rem',
		'&:hover': {}
	},
	icon: {
		fontSize: '22px !important'
	},
	spinnerStyle: {
		color: color.seaGreen,
		width: '60px',
		height: '60px',
		marginTop: '4%'
	},
	Flex: {
		display: 'flex',
		justifyContent: 'center'
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data)),
	parentPageHandler: (data) => dispatch(parentPageActive(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Districts)
