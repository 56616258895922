/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import {color, toastStyle} from '../../assets/css/commonStyle'
import {Button, Grid, makeStyles, Select, Box, Typography, TextField, Divider} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {MDBSpinner} from 'mdb-react-ui-kit'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {Editor} from 'react-draft-wysiwyg'
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js'
import axios from 'axios'
import {aws_url, base_url} from '../../config/config'
import CloudIcon from '../../assets/svg/cloud_upload.svg'
import DefaultImage from '../../assets/svg/photo_size_select_actual.svg'
import mapboxgl from '!mapbox-gl'
import {generateMap} from '../common/generateMap'

const NewEditAsset = ({updateData, goBackCB, setOpenScreenCB, ...props}) => {
    const classes = useStyles()

    const [assetId, setAssetId] = useState('')
    const [assetName, setAssetName] = useState('')
    const [assetType, setAssetType] = useState(updateData !== undefined ? updateData.type : 'no_value')
    const [icon, setIcon] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [featuredImage, setFeaturedImage] = useState('')
    const [optionalImages, setOptionalImages] = useState([])
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const [newFileName, setNewFileName] = useState('')
    const [isNewImgUpload, setIsNewImgUpload] = useState(false)
    const [isNewOptImgUpload, setIsNewOptImgUpload] = useState(false)
    const [buttonSpinner, setButtonSpinner] = useState(false)
    const errorMessage = 'Invalid image type. Please try again.'
    const [session, setSession] = useState(true)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        getCategories()
    }, [])

    useEffect(() => {
        if (updateData !== undefined) {
            setAssetId(updateData._id)
            setAssetName(updateData.name)
            setIcon(updateData.icon)
            setAssetType(updateData.type)
            setLongitude(updateData.longitude)
            setLatitude(updateData.latitude)
            setFeaturedImage(updateData.featuredImage)
            setOptionalImages(updateData.optionalImages)
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(updateData.content))))
            generateMap(updateData.longitude, updateData.latitude, setLongitude, setLatitude, updateData)
        } else {
            if(props.store !== undefined  ) {
                setLongitude(props.store.lngLat[0])
                setLatitude(props.store.lngLat[1])
                generateMap(props.store.lngLat[0], props.store.lngLat[1], setLongitude, setLatitude)
            } else {
                generateMap(-97.51573401818237, 35.473085788128714, setLongitude, setLatitude)
            }
        }
    }, [updateData])

    const validateImage = (arr) => {
        let isImage = false
        const allowedTypes = ['jpg', 'jpeg', 'png', 'svg', 'webp', 'gif']
        for (let i = 1; i < allowedTypes.length; i++) {
            if (arr.includes(allowedTypes[i])) {
                isImage = true
            }
        }
        return isImage
    }

    const handleSetLocation = () => {
        generateMap(longitude, latitude)
    }

    const addImage = ({target}, currentImage) => {
        const reader = new FileReader()
        if (target.files[0]) {
            reader.readAsDataURL(target.files[0])
        }
        reader.onload = () => {
            if (reader.readyState === 2) {
                const base64 = reader.result
                const isImage = validateImage(base64.substring(0, 30))
                if (isImage) {
                    if (currentImage === 'feature') {
                        setFeaturedImage(base64)
                        setIsNewImgUpload(true)
                    } else if (currentImage === 'optional') {
                        setOptionalImages((optImages) => {
                            return [...optImages, base64]
                        })
                        setIsNewOptImgUpload(true)
                    }
                } else {
                    toast.error(errorMessage, {position: toastStyle.position, autoClose: toastStyle.closeDuration})
                }
            }
        }
    }

    const removeOptionalImages = (item) => {
        const newImages = optionalImages.filter((image) => image !== item)
        setOptionalImages(newImages)
    }

    const handleCloseSessionModal = () => {
        getCategories()
        setSession(true)
    }

    const getCategories = async () => {
        await axios
            .post(`${base_url}/admin/settings/search_data/view_categories`, {enabled: true})
            .then((response) => {
                if (response.data.success) {
                    setCategories(response.data.data.categories)
                }
            })
            .catch(function (error) {
                toast.error('Something went wrong in fetching asset categories. Please try again later.', {
                    position: toastStyle.position,
                    autoClose: toastStyle.closeDuration
                })
                return error
            })
    }

    const getContent = (state) => {
        return JSON.stringify(convertToRaw(state.getCurrentContent()))
    }

    const updateAsset = async () => {
        let convertedContent = getContent(editorState)

        if (assetName.length === 0) {
            toast.error('Live asset name is required.', {
                position: toastStyle.position,
                autoClose: toastStyle.closeDuration
            })
        } else {
            setButtonSpinner(true)
            await axios
                .post(
                    `${base_url}/admin/live_assets/update`,
                    {
                        id: assetId,
                        name: assetName,
                        assetType: assetType,
                        icon: icon,
                        latitude: latitude,
                        longitude: longitude,
                        featuredImage: featuredImage,
                        optImages: optionalImages,
                        content: convertedContent
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('auth_token')}`
                        }
                    }
                )
                .then((response) => {
                    if (response.data.success) {
                        toast.success('Live asset updated successfully.', {
                            position: toastStyle.position,
                            autoClose: toastStyle.closeDuration
                        })
                        setButtonSpinner(false)
                        setOpenScreenCB(false)
                        goBackCB()
                    } else {
                        toast.error('An error occurred.', {
                            position: toastStyle.position,
                            autoClose: toastStyle.closeDuration
                        })
                    }
                })
                .catch(function (error) {
                    toast.error('Something went wrong. Please try again later.', {
                        position: toastStyle.position,
                        autoClose: toastStyle.closeDuration
                    })
                })
        }
    }

    const addNewAsset = async () => {
        let convertedContent = getContent(editorState)
        if (assetName.length === 0) {
            toast.error('Live Asset name is required.', {
                position: toastStyle.position,
                autoClose: toastStyle.closeDuration
            })
        }
        if (assetType === 'no_value') {
            toast.error('Asset category type is required. Please add some asset category first in settings tabs', {
                position: toastStyle.position,
                autoClose: toastStyle.closeDuration
            })
        } else {
            setButtonSpinner(true)
            await axios
                .post(
                    `${base_url}/admin/live_assets/add`,
                    {
                        name: assetName,
                        assetType: assetType,
                        icon: icon,
                        latitude: latitude,
                        longitude: longitude,
                        featuredImage: featuredImage,
                        optionalImages: optionalImages,
                        content: convertedContent
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('auth_token')}`
                        }
                    }
                )
                .then((response) => {
                    if (response.data.success) {
                        toast.success('Live asset added successfully.', {
                            position: toastStyle.position,
                            autoClose: toastStyle.closeDuration
                        })
                        setButtonSpinner(false)
                        setOpenScreenCB(false)
                        goBackCB()
                    } else {
                        toast.error('Live asset already exist.', {
                            position: toastStyle.position,
                            autoClose: toastStyle.closeDuration
                        })
                    }
                    setButtonSpinner(false)
                })
                .catch(function (error) {
                    toast.error('Something went wrong. Please try again later.', {
                        position: toastStyle.position,
                        autoClose: toastStyle.closeDuration
                    })
                    return error
                })
        }
    }

    let wordCount = 0
    if (convertToRaw(editorState.getCurrentContent())?.blocks?.length > 0) {
        for (const block of convertToRaw(editorState.getCurrentContent())?.blocks) {
            wordCount += block.text.length
        }
    }

    return (
        <div className={classes.root} xs={12} sm={12} md={12} lg={12}>
            <Grid item container className={classes.items} xs={12} sm={12} md={6} lg={6}>
                <Typography variant="h4" className={classes.heading}>
                    New / Edit Live Asset
                </Typography>
                <Box className={classes.detailsContainer}>
                    <Typography variant="body1" className={classes.textLabel}>
                        Live Asset Name
                    </Typography>
                    <TextField type="text" value={assetName} size="small" className={classes.textField} variant="outlined" fullWidth onChange={(e) => setAssetName(e.target.value)} />
                    <Typography variant="body1" className={classes.textLabel}>
                        Type of Live Asset
                    </Typography>
                    <Select
                        name="type"
                        id="type"
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left'
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left'
                            },
                            getContentAnchorEl: null,
                        }}
                        variant="outlined"
                        value={assetType}
                        className={classes.selectBox}
                        onChange={(e) => setAssetType(e.target.value)}>
                        <option value="no_value" className={classes.option}>
                            Type of Live Asset
                        </option>
                        {categories.length > 0 &&
                        categories.map((item, index) => {
                            return (
                                <option key={index} value={item.name} className={classes.option}>
                                    {item.name}
                                </option>
                            )
                        })}
                    </Select>
                    <Typography variant="body1" className={classes.textLabel}>
                        Live Asset Preview
                    </Typography>
                    <Typography variant="body1" className={classes.descriptionLabel}>
                        Click anywhere in the map to place marker at that location. Drag to move it.
                    </Typography>
                    <Box className={classes.mapImageBox}>
                        <div className={classes.mapImage} id="mapContainer"></div>
                        <pre id="coordinates" className={classes.coordinates}></pre>
                    </Box>
                    <Grid item container spacing={2} xs={12} sm={12} md={12} lg={12} variant="body1" className={classes.locationContainer}>
                        <Grid item container xs={12} sm={12} md={4} lg={4}>
                            <Grid item className={classes.textLeft} item xs={12} sm={12} md={12} lg={12}>
                                Longitude
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField type="text" value={longitude} size="small" className={classes.smallTextField} variant="outlined" fullWidth onChange={(e) => setLongitude(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={12} md={4} lg={4}>
                            <Grid className={classes.textLeft} item xs={12} sm={12} md={12} lg={12}>
                                Latitude
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField type="text" value={latitude} size="small" className={classes.smallTextField} variant="outlined" fullWidth onChange={(e) => setLatitude(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid className={classes.btnAlign} item xs={12} sm={12} md={4} lg={4}>
                            <Button variant="contained" className={classes.setLocationButton} size="small" onClick={() => generateMap(longitude, latitude)}>
                                Set Location
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth" className={classes.divider} />
                    <Typography variant="body1" className={classes.textLabel}>
                        Featured Image
                    </Typography>
                    <Grid container>
                        <Grid item xs={3} sm={3} md={2} lg={2} className={classes.imageBox}>
                            {featuredImage ? <img src={isNewImgUpload ? featuredImage : `${aws_url}/${featuredImage}`} className="featuredImage" /> : <img src={DefaultImage} className="defaultFeaturedImage" />}
                        </Grid>
                        <Grid item xs={8} sm={8} md={10} lg={10}>
                            <label htmlFor="upload2" className={classes.inputLabel} aria-label="upload picture">
                                Upload 1 File
                            </label>
                            <Typography variant="body1" className={classes.featureLabel}>
                                10 mb max
                            </Typography>
                            <input type="file" size="small" className={classes.uploadFile} id="upload2" onChange={(e) => addImage(e, 'feature')} />
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth" className={classes.divider} />
                    <Typography variant="body1" className={classes.textLabel}>
                        Addititional Images (optional)
                    </Typography>
                    <div htmlFor="upload3" className={classes.uploadOptionalImagesDashed} id="dropArea">
                        <label htmlFor="upload3" className={classes.uploadLabel} aria-label="upload picture">
                            <img src={`${CloudIcon}`} />
                            <br />
                            Drop your file here or <span style={{color: color.seaGreen, marginLeft: '0.3rem'}}>browse</span>
                        </label>
                        <input type="file" size="small" className={classes.uploadFile} id="upload3" onChange={(e) => addImage(e, 'optional')} />
                    </div>
                    <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.additionalImageBox}>
                        {optionalImages.length > 0 &&
                        optionalImages.map((item, index) => {
                            return (
                                <Grid container key={index} className={classes.optionalImageContainer}>
                                    <Grid item xs={3} sm={3} md={2} lg={2} className={classes.imageBox}>
                                        <img src={item.startsWith('data:image') ? `${item}` : `${aws_url}/${item}`} className={classes.additionalImage} />
                                        {/* {item ? (item.startsWith('1652') ? `${aws_url}/${item}` : `${item}`) : DefaultImage} */}
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={8} lg={8} className={classes.imageDescriptionBox}>
                                        {buttonSpinner ? (
                                            <div>
                                                <label className={classes.ll} aria-label="upload picture">
                                                    File Uploading
                                                </label>
                                                <Typography variant="body1" className={classes.optImageLabel}>
                                                    Loading ...
                                                </Typography>
                                            </div>
                                        ) : (
                                            <div>
                                                {item.startsWith('data:image') ? (
                                                    <div>
                                                        <label className={classes.ll} aria-label="upload picture">
                                                            File to upload
                                                        </label>
                                                        <Typography variant="body1" className={classes.optImageLabel}>
                                                            {newFileName}
                                                        </Typography>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <label className={classes.ll} aria-label="upload picture">
                                                            File already exist
                                                        </label>
                                                        <Typography variant="body1" className={classes.optImageLabel}>
                                                            {item}
                                                        </Typography>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} lg={2} className={classes.imageDeleteContainer}>
                                        <Button size="small" onClick={() => removeOptionalImages(item)}>
                                            <Close className="deleteIcon" fontSize="xs" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Divider variant="fullWidth" className={classes.divider} />
                    <Typography variant="body1" className={classes.textLabel}>
                        Live Asset Information Content
                    </Typography>
                    <Typography variant="body1" className={classes.additionalContentDescription}>
                        {wordCount <= 1500 ? wordCount : 1500} of 1500 words
                    </Typography>
                    <Editor editorState={editorState} value={editorState} onEditorStateChange={setEditorState} wrapperClassName={classes.wrapperClass} editorClassName={classes.editorClass} toolbarClassName={classes.toolbarClass} />
                    <div className={classes.buttonContainer}>
                        <Button variant="outlined" className={classes.cancelButton} size="small" onClick={goBackCB}>
                            Cancel
                        </Button>
                        {buttonSpinner ? (
                            <Button variant="contained" className={classes.saveButton} size="small">
                                <MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner>
                            </Button>
                        ) : updateData !== undefined ? (
                            <Button variant="contained" className={classes.saveButton} size="small" onClick={updateAsset}>
                                Save Changes
                            </Button>
                        ) : (
                            <Button variant="contained" className={classes.saveButton} size="small" onClick={addNewAsset}>
                                Save Changes
                            </Button>
                        )}
                    </div>
                </Box>
            </Grid>
        </div>
    )
}



const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: `${color.pagesBg} !important`,
        display: 'flex',
        justifyContent: 'center'
    },
    items: {},
    detailsContainer: {
        backgroundColor: '#fff',
        borderRadius: '12px',
        padding: '32px',
        gap: '40px'
    },
    textField: {
        color: color.gray,
        borderRadius: '12px !important',
        padding: '8px 16px',
        gap: '12px',
        border: `2px solid ${color.newBorder}`,
        flex: 'none',
        order: 1,
        alignSelf: 'stretch',
        flexGrow: 0
    },
    smallTextField: {
        color: color.gray,
        borderRadius: '12px !important',
        padding: '8px 16px',
        gap: '12px',
        border: `2px solid ${color.newBorder}`,
        flex: 'none',
        order: 1,
        alignSelf: 'stretch',
        flexGrow: 0
    },
    inputLabel: {
        color: color.black,
        fontSize: '16px',
        fontWeight: 600,
        margin: '0.2rem 1rem',
        letterSpacing: '1px',
        '&:hover': {
            backgroundColor: color.ultraLightGreen
        }
    },
    locationContainer: {
        marginTop: '1rem',
        textAlign: 'center'
    },
    textLabel: {
        marginTop: '1rem',
        marginBottom: '1rem',
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '15px',
        color: '#414A52'
    },
    descriptionLabel: {
        marginTop: '1rem',
        marginBottom: '1rem',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',

        color: '#999A9C'
    },
    featureLabel: {
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        position: 'relative',
        left: '1rem',
        color: '#999A9C'
    },
    optImageLabel: {
        color: '#999A9C',
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px'
    },
    setLocationButton: {
        color: color.black,
        backgroundColor: color.cancel,
        fontWeight: '600',
        fontSize: '14px',
        textTransform: 'capitalize',
        padding: '14px 24px',
        '&:hover': {
            backgroundColor: color.hoverCancel,
            color: color.white
        },
        position: 'relative',
        bottom: '0.4rem',
        borderRadius: '12px'
    },
    wrapperClass: {
        border: `1px solid ${color.newBorder}`,
        borderRadius: '0.5rem',
        padding: '0.2rem'
    },
    toolbarClass: {
        display: 'none',
        border: `1px solid  ${color.newBorder}`
    },
    editorClass: {
        padding: '0.1rem',
        color: color.darkGray,
        height: '12rem'
    },
    selectBox: {
        width: '100%',
        textAlign: 'left',
        marginLeft: 0,
        color: color.gray,
        borderRadius: '12px !important',
        cursor: 'pointer',
        gap: '12px',
        border: '2px solid #DDDDDE',
        flex: 'none',
        order: 1,
        alignSelf: 'stretch',
        flexGrow: 0,
    },
    option: {
        width: '100%',
        padding: '14px 18px',
        textAlign: 'left',
        color: color.gray,
        cursor: 'pointer',
        borderBottom: '1px solid #DDDDDE',
        overflow: 'hidden',
        '&:hover': {backgroundColor: color.cancel},
        '&:last-child': {border: 'none', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px'},
        '&:first-child': {borderTopLeftRadius: '12px', borderTopRightRadius: '12px'}
    },

    mapImageBox: {height: '23rem', borderRadius: '1rem'},
    mapImage: {width: '100%', height: '100%', borderRadius: '1rem', border: 'none'},
    imageBox: {height: '4rem', borderRadius: '1rem', backgroundColor: color.cancelBtnBg},
    imageDescriptionBox: {
        padding: '0 1rem'
    },
    additionalImageBox: {
        margin: '1rem 0 0 0 '
    },
    additionalImage: {
        width: '100%',
        height: '100%',
        borderRadius: '1rem',
        border: '1px solid gray',
        objectFit: 'cover'
    },
    optionalImageContainer: {
        width: '100%',
        padding: '1rem 0 0 0'
    },
    imageDeleteContainer: {display: 'flex', textAlign: 'right', alignItems: 'center'},
    deletebutton: {
        backgroundColor: 'transparent',
        color: color.darkGray,
        position: 'absolute',
        marginLeft: '-3.6rem',
        marginTop: '-0.2rem',
        width: '0.1rem',
        border: 'none'
    },
    delete: {
        width: '100%',
        height: '100%',
        transform: 'scale(0.6)',
        '&:hover': {
            color: color.gray
        }
    },
    divider: {
        margin: '2rem 0px 2rem 0px',
        border: '1px solid rgba(227, 227, 228, 0.45)'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    cancelButton: {
        width: '140px',
        backgroundColor: '#F0F0F0',
        color: color.black,
        outline: color.gray,
        fontWeight: '400',
        fontSize: '14px',
        textTransform: 'capitalize',
        margin: '8px',
        padding: '8px 20px',
        letterSpacing: '1px',
        border: 'none',
        borderRadius: '1rem',
        '&:hover': {
            backgroundColor: color.lightGray
        }
    },
    saveButton: {
        width: '150px',
        color: color.white,
        backgroundColor: color.buttonBg,
        fontWeight: '400',
        fontSize: '14px',
        textTransform: 'capitalize',
        letterSpacing: '1px',
        margin: '8px',
        padding: '8px 20px',
        border: 'none',
        borderRadius: '1rem',
        '&:hover': {
            backgroundColor: color.hoverButtonBg
        }
    },
    spinnerStyle: {
        color: color.white,
        width: '24px',
        height: '24px'
    },
    textLeft: {
        textAlign: 'left',
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '15px',
        marginTop: '1rem',
        marginBottom: '1rem',
        color: '#414A52'
    },
    btnAlign: {
        display: 'inline-block',
        alignSelf: 'flex-end'
    },
    uploadOptionalImagesDashed: {
        width: '100%',
        border: '2px dashed #00A850 ',
        borderRadius: '12px !important',
        paddingTop: '2rem',
        display: 'flex-inline',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: color.ultraLightGreen,
        cursor: 'pointer'
    },
    uploadLabel: {cursor: 'pointer'},
    uploadFile: {opacity: 0},
    heading: {
        margin: '1rem 0',
        fontFamily: 'ZillaSlab-Bold'
    },
    coordinates: {
        background: 'rgba(0, 0, 0, 0.5)',
        color: color.white,
        position: 'relative',
        bottom: '2.9rem',
        left: '6rem',
        width: '13.2rem',
        padding: '5px 10px',
        margin: 0,
        fontSize: '11px',
        lineHeight: '18px',
        borderRadius: '3px',
        display: 'none'
    },
    additionalContentDescription: {
        marginTop: '1rem',
        marginBottom: '1rem',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#999A9C'
    },


}))

export default NewEditAsset
