/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {makeStyles, Typography, Avatar, Divider, Select, Box, Button, TextField, Grid, Checkbox} from '@material-ui/core'
import {AddCircle, Edit, Settings, Subtitles, Navigation, Map, Publish, BorderTopSharp, ContactSupport, Add, Save} from '@material-ui/icons'
import {color, toastStyle} from '../assets/css/commonStyle'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import AssetCategoryTab from './AssetCategorySubTab'
import PopupTab from './PopupTab'
import SubSettingsTab from './SubSettingsTab'
import {connect} from 'react-redux'
import {storeLogin} from '../redux/actions/actions'
import {validate} from '../components/common/validate'
import SessionExpiredModal from '../components/common/SessionExpireModal'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const SettingsPage = (props) => {
	const classes = useStyles()

	const [firstTab, setFirstTab] = useState(false)
	const [secondTab, setSecondTab] = useState(false)
	const [thirdTab, setThirdTab] = useState(false)

	const [session, setSession] = useState(true)

	useEffect(() => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			setFirstTab(true)
		}
	}, [])

	const handleCloseSessionModal = () => {
		setSession(true)
	}

	const handleMapItemsTab = () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			setFirstTab(true)
			setSecondTab(false)
			setThirdTab(false)
		}
	}

	const handlePopupTab = () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			setFirstTab(false)
			setSecondTab(true)
			setThirdTab(false)
		}
	}

	const handleSettingsTab = () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			setFirstTab(false)
			setSecondTab(false)
			setThirdTab(true)
		}
	}

	return (
		<div className={classes.root}>
			{session ? (
				<div>
					<Typography variant="h4" className={classes.heading}>
						Settings
					</Typography>
					<Box>
						<Button variant="outlined" className={`${firstTab ? classes.activeButton : classes.button}`} id="button1" size="small" onClick={handleMapItemsTab}>
							Map Items
						</Button>
						<Button variant="outlined" className={`${secondTab ? classes.activeButton : classes.button}`} id="button2" size="small" onClick={handlePopupTab}>
							Popup Content
						</Button>
						<Button variant="outlined" className={`${thirdTab ? classes.activeButton : classes.button}`} id="button5" size="small" onClick={handleSettingsTab}>
							Settings
						</Button>
					</Box>
					<Divider variant="fullWidth" className={classes.dividerFull} />
					{firstTab && <AssetCategoryTab />}
					{secondTab && <PopupTab />}
					{thirdTab && <SubSettingsTab />}
				</div>
			) : (
				<SessionExpiredModal closeCB={handleCloseSessionModal} />
			)}
		</div>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	button: {
		marginTop: theme.spacing(3),
		marginRight: '0.6rem',
		fontWeight: '700',
		border: 'none',
		color: color.tabColor,
		fontSize: '16px',
		textTransform: 'capitalize',
		padding: '14px 18px',
		borderRadius: '0 !important',
		'&:hover': {
			color: color.buttonBg
		}
	},
	activeButton: {
		marginTop: theme.spacing(3),
		marginRight: '0.6rem',
		border: 'none',
		fontWeight: '700',
		borderRadius: '0 !important',
		borderBottom: `2px solid ${color.buttonBg}`,
		padding: '14px 18px',
		color: color.buttonBg,
		fontSize: '16px',
		textTransform: 'capitalize',
		'&:hover': {
			color: color.hoverButtonBg
		}
	},
	dividerFull: {
		margin: '20px 0px 20px 0px'
	},
	textField: {
		border: `1px solid ${color.lightGray} !important`,
		borderRadius: '5px !important',
		backgroundColor: color.lightGray,
		width: '40%',
		marginRight: '2rem'
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage)
