/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import axios from 'axios'
import {makeStyles, Typography, Divider} from '@material-ui/core'
import {color, toastStyle} from '../assets/css/commonStyle'
import {toast} from 'react-toastify'
import {MDBDataTableV5} from 'mdbreact'
import {MDBSpinner} from 'mdb-react-ui-kit'
import {base_url} from '../config/config'
import Pagination from '../components/common/Pagination'
import {connect} from 'react-redux'
import {storeLogin} from '../redux/actions/actions'
import {validate} from '../components/common/validate'
import SessionExpiredModal from '../components/common/SessionExpireModal'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const History = (props) => {
	let _history = useHistory()
	const classes = useStyles()

	const [currentPage, setCurrentPage] = useState(1)
	const [length, setLength] = useState(0)
	const [spinner, setSpinner] = useState(false)
	const [session, setSession] = useState(true)
	const [activities, setActivities] = useState({
		columns: [
			{
				label: 'Activity Type',
				field: 'type'
			},
			{
				label: 'Record ID',
				field: 'activityId'
			},
			{
				label: 'Table Name',
				field: 'collection'
			},
			{
				label: 'Created at',
				field: 'created'
			},
			{
				label: 'Updated at',
				field: 'updated'
			}
		],
		rows: [
			{name: 'Add user', time: '2022-03-31T14:19:21.915Z', activity: 'add', collection: 'admins', updated: '2022-04-21T08:17:45.733Z'},
			{name: 'Delete Asset Category', time: '2022-03-31T14:19:21.915Z', activity: 'delete', collection: 'assetcategories', updated: '2022-04-21T07:20:45.733Z'},
			{name: 'Add map geojson', time: '2022-03-31T14:19:21.915Z', activity: 'update', collection: 'trails', updated: '2022-04-21T07:17:12.733Z'}
		]
	})

	useEffect(() => {
		if (localStorage.getItem('auth_token') !== null || localStorage.getItem('auth_token') !== undefined) {
			viewActivities(currentPage)
		} else {
			_history.push('/login')
		}
	}, [])

	function getData(page) {
		viewActivities(page)
		setCurrentPage(page)
	}

	const handleCloseSessionModal = () => {
		viewActivities()
		setSession(true)
	}

	const viewActivities = async (page) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			setSpinner(true)
			await axios
				.post(
					`${base_url}/admin/history/view_history`,
					{page: page},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('auth_token')}`
						}
					}
				)
				.then((response) => {
					if (response.data.success) {
						setSpinner(false)
						setLength(response.data.data.history.length)
						setActivities((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						response.data.data.history.map((data) => {
							createTable(data)
						})
					} else {
						setSpinner(false)
					}
				})
				.catch(function (error) {
					setSpinner(false)
					toast.error('Something went wrong in fetching history data. Please try again later.', {position: toastStyle.position, autoClose: toastStyle.closeDuration})
					return error
				})
		}
	}

	const createTable = (row) => {
		let created = row.created_at.split('T')[0] + ' | ' + row.created_at.split('T')[1].split('.')[0]
		let updated = row.updated_at.split('T')[0] + ' | ' + row.updated_at.split('T')[1].split('.')[0]
		let _obj = {
			type: row.type,
			collection: row.table,
			activityId: row.activityId,
			created: created,
			updated: updated
		}
		setActivities((preValue) => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	return (
		<div className={classes.root}>
			{session ? (
				<div>
					<Typography variant="h4" className={classes.heading}>
						Admin History
					</Typography>
					<Divider variant="fullWidth" className={classes.divider} />
					{spinner ? (
						<div className={classes.Flex}>
							<MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner>
						</div>
					) : (
						<div>
							<MDBDataTableV5 className={classes.table} hover entries={25} data={activities} sorting={false.toString()} sortable={false} fullPagination={false} paging={false} info={false} searchTop={false} searchBottom={false} />
							<Pagination viewCB={getData} len={length} page={currentPage} />
						</div>
					)}
				</div>
			) : (
				<SessionExpiredModal closeCB={handleCloseSessionModal} />
			)}
		</div>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		flexGrow: 1,
		backgroundColor: color.pagesBg,
		padding: '2rem'
	},
	button: {
		marginTop: theme.spacing(3),
		backgroundColor: color.seaGreen,
		color: color.white,
		fontSize: '11px',
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: color.darkGreen
		}
	},
	table: {
		marginTop: '20px'
	},
	heading: {
		fontWeight: '600',
		fontSize: '36px',
		color: color.black
	},
	divider: {
		margin: '20px 0px 20px 0px'
	},
	spinnerStyle: {
		color: color.seaGreen,
		width: '60px',
		height: '60px',
		marginTop: '4%'
	},
	Flex: {
		display: 'flex',
		justifyContent: 'center'
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(History)
