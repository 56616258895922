/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useEffect, useState} from 'react'
import {color} from '../../assets/css/commonStyle'
import {Button, Grid, makeStyles, Modal, Card, CardHeader, CardContent, CardActions, TextField, Typography} from '@material-ui/core'
import {MDBSpinner} from 'mdb-react-ui-kit'

const AddKeywordsModal = ({openCB, closeCB, updateData, modalTitle, addActionCB, updateActionCB, buttonSpinner}) => {
	const classes = useStyles()
	const [keyword, setKeyword] = useState('')


	useEffect(() => {
		if (updateData !== undefined && updateData !== '') {
			if (updateData.keyword) {
				setKeyword(updateData.keyword)
			}

		}
	}, [updateData])

	const updateAction = () => {
			updateActionCB(keyword)
	}

	const addAction = () => {
			addActionCB(keyword)
	}

	return (
		<div className={classes.modal}>
			<Modal open={openCB} onClose={closeCB} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
				<div className={classes.paper}>
					<Card>
						<CardHeader title={<h3>{modalTitle}</h3>} className={classes.cardHead} />
						<CardContent>
							<div>
								<Typography variant="body1" className={classes.textLabel}>
									Keyword
								</Typography>
								<TextField type="text" size="small" className={classes.textField} value={keyword} variant="outlined" label="Name" fullWidth onChange={(e) => setKeyword(e.target.value)} />
							</div>
						</CardContent>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<CardActions className={classes.cardActions}>
								<Grid item className={classes.fieldsContainer} xs={12} sm={12}>
									<Button variant="outlined" className={classes.cancelButton} size="small" onClick={closeCB}>
										Cancel
									</Button>
									{updateData !== undefined ? (
										<Button variant="contained" className={classes.addButton} size="small" onClick={updateAction}>
											{buttonSpinner ? <MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner> : <label className={classes.buttonLabel}>Save Changes</label>}
										</Button>
									) : (
										<Button variant="contained" className={classes.addButton} size="small" onClick={addAction}>
											{buttonSpinner ? <MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner> : <label className={classes.buttonLabel}>Save Changes</label>}
										</Button>
									)}
								</Grid>
							</CardActions>
						</Grid>
					</Card>
				</div>
			</Modal>
		</div>
	)
}

const useStyles = makeStyles((theme) => ({
	paper: {
		textAlign: 'left',
		color: color.black,
		width: '40%',
		position: 'absolute',
		left: '0',
		right: '0',
		margin: 'auto',
		borderRadius: '5px',
		marginTop: '10px',
		[theme.breakpoints.down('md')]: {
			width: '80%'
		},
		[theme.breakpoints.up('md')]: {
			width: '40%'
		}
	},
	textLabel: {
		marginTop: '15px',
		color: color.textColor,
		fontSize: '16px',
		fontWeight: '700'
	},
	activeTextLabel: {
		color: color.textColor,
		fontSize: '16px',
		fontWeight: '700',
		position: 'absolute',
		top: '0.24rem',
		left: '5rem'
	},
	textField: {
		marginTop: '15px',
		border: `1px solid ${color.lightGray} !important`,
		borderRadius: '5px !important'
	},
	fieldsContainer: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	fileUploadContainer: {},
	fileUploader: {},
	imageContainer: {
		height: '5rem'
	},
	image: {
		position: 'relative',
		top: 0,
		left: 0,
		padding: 0,
		borderRadius: '12px',
		border: 'none',
		backgroundColor: color.green
	},
	iconImage: {
		width: '64px',
		height: '44px',
		borderRadius: '12px',
		margin: 0
	},
	label: {
		color: color.primary
	},
	cardHead: {
		backgroundColor: color.white,
		color: color.black
	},
	cardActions: {
		padding: '11px 10px 15px 10px',
		justifyContent: 'right'
	},
	cancelButton: {
		width: '140px',
		backgroundColor: '#F0F0F0',
		color: color.black,
		outline: color.gray,
		fontWeight: '400',
		fontSize: '14px',
		textTransform: 'capitalize',
		margin: '8px',
		padding: '8px 20px',
		letterSpacing: '1px',
		border: 'none',
		borderRadius: '1rem',
		'&:hover': {
			backgroundColor: color.lightGray
		}
	},
	buttonLabel: {margin: 0},
	addButton: {
		width: '150px',
		color: color.white,
		backgroundColor: color.buttonBg,
		fontWeight: '400',
		fontSize: '14px',
		textTransform: 'capitalize',
		letterSpacing: '1px',
		margin: '8px',
		padding: '8px 20px',
		border: 'none',
		borderRadius: '1rem',
		'&:hover': {
			backgroundColor: color.hoverButtonBg
		}
	},
	spinnerStyle: {
		color: color.white,
		width: '12px',
		height: '12px'
	},
	hide: {display: 'none'},
	uploadFile: {display: 'none'},
	iconContainer: {marginTop: '1.6rem'},
	uploadOptionalImagesDashed: {
		width: '100%',
		border: '2px dashed #00A850',
		borderRadius: '12px !important',
		paddingTop: '2rem',
		paddingBottom: '2rem',
		display: 'flex-inline',
		justifyContent: 'center',
		textAlign: 'center',
		backgroundColor: color.ultraLightGreen,
		cursor: 'pointer'
	},
	uploadLabel: {cursor: 'pointer'}
}))
export default AddKeywordsModal
