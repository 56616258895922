/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {makeStyles, Typography, Button, Box, Grid, Avatar} from '@material-ui/core'
import {AddCircle, Edit} from '@material-ui/icons'
import {color, toastStyle} from '../assets/css/commonStyle'
import {toast} from 'react-toastify'
import {MDBDataTableV5} from 'mdbreact'
import {MDBSpinner} from 'mdb-react-ui-kit'
import {base_url} from '../config/config'
import AddSettingModal from '../components/common/AddSettingsModal'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import validUrl from 'valid-url'
import {connect} from 'react-redux'
import {storeLogin} from '../redux/actions/actions'
import {validate} from '../components/common/validate'
import SessionExpiredModal from '../components/common/SessionExpireModal'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const Links = (props) => {
	const classes = useStyles()

	const [updateData, setUpdateData] = useState(undefined)
	const [updateId, setUpdateId] = useState('')
	const [openModal, setOpenModal] = useState(false)
	const [title, setTitle] = useState('')
	const [url, setUrl] = useState('')
	const [order, setOrder] = useState('')
	const [active, setActive] = useState(false)
	const [spinner, setSpinner] = useState(false)
	const [session, setSession] = useState(true)

	const [links, setLinks] = useState({
		columns: [
			{
				label: 'Name',
				field: 'name',
				width: 270,
				attributes: {'aria-controls': 'DataTable', 'aria-label': 'Name'}
			},
			{label: 'Link Order', field: 'order', width: 20},
			{label: 'Enabled', field: 'enabled', type: 'checkbox'},
			{label: '', field: 'edit', width: 200, sort: String('disabled')}
		],
		rows: []
	})

	useEffect(() => {
		viewLinks()
	}, [])

	const handleEdit = (data) => {
		setUpdateData(data)
		setUpdateId(data._id)
		setTitle(data.title)
		setUrl(data.url)
		setOrder(data.order)
		setActive(data.enabled)
		setOpenModal(true)
	}

	const handleAddNew = () => {
		setOpenModal(true)
	}

	const cancel = () => {
		setOpenModal(false)
		setUpdateData(undefined)
	}

	const handleCloseSessionModal = () => {
		viewLinks()
		setSession(true)
	}

	const viewLinks = async (page) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			setSpinner(true)
			await axios
				.get(`${base_url}/admin/settings/links/view_links`)
				.then((response) => {
					if (response.data.success) {
						setSpinner(false)
						setLinks((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						response.data.data.links.map((data) => {
							createTable(data)
						})
					} else setSpinner(false)
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					setSpinner(false)
					return error
				})
		}
	}

	const updateLink = async (data) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			if (data.title.length === 0) {
				toast.error('Link title is required.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else if (data.url.length === 0) {
				toast.error('URL is required.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			}  else {
				await axios
					.post(
						`${base_url}/admin/settings/links/update`,
						{
							id: updateId,
							title: data.title,
							url: data.url,
							order: data.order,
							enabled: data.active
						},
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem('auth_token')}`
							}
						}
					)
					.then((response) => {
						if (response.data.success) {
							toast.success('Link updated successfully.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
							setOpenModal(false)
							viewLinks()
						} else {
							toast.error('Something went wrong. Please try again later', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
						}
					})
					.catch(function (error) {
						toast.error('Something went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						return error
					})
			}
		}
	}

	const handleEnable = async (id, enabled) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			await axios
				.post(
					`${base_url}/admin/settings/links/update`,
					{
						id: id,
						enabled: !enabled
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('auth_token')}`
						}
					}
				)
				.then((response) => {
					if (response.data.success) {
						toast.success('Enable status updated successfully.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						viewLinks()
					} else {
						toast.error('Soemthing went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					return error
				})
		}
	}

	const addLink = async (data) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			if (data.title.length === 0) {
				toast.error('Link title is required.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else if (data.url.length === 0) {
				toast.error('URL is required.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else if (!validUrl.isUri(data.url)) {
				toast.error('Invalid URL. Please try again', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else {
				await axios
					.post(
						`${base_url}/admin/settings/links/add`,
						{
							title: data.title,
							url: data.url,
							order: data.order,
							enabled: data.active
						},
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem('auth_token')}`
							}
						}
					)
					.then((response) => {
						if (response.data.success) {
							toast.success('Link added successfully.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
							setOpenModal(false)
							viewLinks()
						} else {
							toast.error('Link already exist.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
						}
					})
					.catch(function (error) {
						toast.error('Something went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						return error
					})
			}
		}
	}

	const createTable = (row) => {
		let _obj = {
			name: (
				<>
					<span>{row.title}</span> <br /> <span style={{color: color.navBarBg}}>{row.url}</span>
				</>
			),
			enabled: (
				<label className="switch">
					<input type="checkbox" onChange={() => handleEnable(row)} checked={row.enabled} />
					<span className={`slider round`}></span>
				</label>
			),
			order: (
				<>
					<span className={classes.orderColumn}>{row.order}</span>
				</>
			),
			edit: (
				<Avatar variant="square" className={classes.editButton}>
					<Edit
						className="editIcon"
						onClick={() => {
							handleEdit(row)
						}}
					/>
				</Avatar>
			)
		}
		setLinks((preValue) => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	return (
		<div className={classes.root}>
			{session ? (
				<div>
					{openModal === false ? (
						<div className={classes.contentContainer}>
							<Grid container>
								<Grid item xs={12} sm={12} md={11} lg={11}>
									<Typography variant="body1" className={classes.tableHeading}>
										Links
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={1} lg={1}>
									<Button variant="contained" className={classes.addButton} startIcon={<AddCircle style={{fontSize: '16px'}} className="addNewIconBlack" />} size="small" onClick={() => handleAddNew()}>
										<Box style={{paddingTop: '1px'}}>Add</Box>
									</Button>
								</Grid>
							</Grid>

							{spinner ? (
								<div className={classes.Flex}>
									<MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner>
								</div>
							) : (
								<div id="links">
									<MDBDataTableV5 className={classes.table} hover entries={25} data={links} fullPagination={false} paging={false} info={false} searchTop={false} searchBottom={false} />
								</div>
							)}
						</div>
					) : (
						<AddSettingModal openCB={openModal} closeCB={cancel} modalTitle="New/Edit Link" updateData={updateData} addActionCB={addLink} updateActionCB={updateLink} callerScreen="link" />
					)}
				</div>
			) : (
				<SessionExpiredModal closeCB={handleCloseSessionModal} />
			)}
		</div>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		height: '100%',
		flexGrow: 1,
		backgroundColor: color.pagesBg,
		padding: '2rem'
	},
	tableHeading: {
		marginTop: '1rem',
		fontSize: '24px',
		fontWeight: '700'
	},
	editButton: {
		fontSize: '11px',
		backgroundColor: 'transparent',
		color: color.white,
		'&:hover': {}
	},
	addButton: {
		position: 'relative',
		top: '20px',
		padding: '8px 20px',
		backgroundColor: color.cancelBtnBg,
		color: color.textColor,
		fontSize: '16px',
		fontWeight: '700',
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: color.hoverCancel
		},
		orderColumn: {
			marginTop: '1rem',
		}
	},
	Flex: {
		display: 'flex',
		justifyContent: 'center'
	},
	spinnerStyle: {
		color: color.white,
		width: '12px',
		height: '12px'
	},
	contentContainer: {
		backgroundColor: color.white,
		borderRadius: '12px',
		padding: '32px',
		gap: '40px'
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Links)
