/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState} from 'react'
import {Cancel} from '@material-ui/icons'
import {Button, makeStyles, Modal, Card, CardHeader, CardContent, CardActions, IconButton, TextField, Select} from '@material-ui/core'
import {color, toastStyle} from '../../assets/css/commonStyle'
import {base_url} from '../../config/config'
import {toast} from 'react-toastify'
import axios from 'axios'
import {connect} from 'react-redux'
import {storeLogin} from '../../redux/actions/actions'
import {validate} from './validate'
import SessionExpiredModal from './SessionExpireModal'

const AddModal = ({openCB, closeCB, ...props}) => {
	const classes = useStyles()
	const [username, setUsername] = useState('')
	const [email, setEmail] = useState('')
	const [role, setRole] = useState('admin')
	const [enabled, setEnabled] = useState(false)
	const [session, setSession] = useState(true)

	const validateEmail = (email) => {
		let validFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		if (email.match(validFormat)) return true
		else return false
	}

	const addNewUser = async () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			if (username.length === 0) {
				toast.error('Username is required', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else if (email === '' || !validateEmail(email)) {
				toast.error('Invalid email. Please try again', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else {
				await axios
					.post(
						`${base_url}/admin/create_user`,
						{
							name: username,
							email: email,
							role: role,
							enabled: enabled
						},
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem('auth_token')}`
							}
						}
					)
					.then((response) => {
						if (response.data.success === true) {
							toast.success('User added successfully', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
							closeCB()
						} else if (response.data.message === 'Email already exist') {
							toast.error('Email already registered. Please try again', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
						}
					})
					.catch(function (error) {
						toast.error('Something went wrong. Please try again later', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						return error
					})
			}
		}
	}

	return (
		<div className={classes.modal}>
			{session ? (
				<Modal open={openCB} onClose={closeCB} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
					<div className={classes.paper}>
						<Card>
							<CardHeader
								title="User Details"
								className={classes.cardHead}
								action={
									<IconButton aria-label="settings" onClick={closeCB}>
										<Cancel />
									</IconButton>
								}
							/>
							<CardContent>
								<TextField type="text" size="small" className={classes.textField} variant="outlined" label="Username" fullWidth onChange={(e) => setUsername(e.target.value)} />
								<TextField type="text" size="small" className={classes.textField} variant="outlined" label="Email" fullWidth onChange={(e) => setEmail(e.target.value)} />
								<Select
									MenuProps={{
										anchorOrigin: {
											vertical: 'bottom',
											horizontal: 'left'
										},
										transformOrigin: {
											vertical: 'top',
											horizontal: 'left'
										},
										getContentAnchorEl: null
									}}
									variant="outlined"
									name="role"
									id="role"
									value={role}
									fullWidth
									className={classes.selectBox}
									onChange={(e) => setRole(e.target.value)}>
									<option value="user" className={classes.option}>
										User
									</option>
									<option value="admin" className={classes.option}>
										Administrator
									</option>
								</Select>
							</CardContent>
							<CardActions className={classes.cardActions}>
								<Button variant="outlined" className={classes.cancelButton} size="small" onClick={closeCB}>
									Cancel
								</Button>
								<Button variant="contained" className={classes.addButton} size="small" onClick={addNewUser}>
									Create user
								</Button>
							</CardActions>
						</Card>
					</div>
				</Modal>
			) : (
				<SessionExpiredModal closeCB={() => setSession(true)} />
			)}
		</div>
	)
}

const useStyles = makeStyles((theme) => ({
	paper: {
		textAlign: 'left',
		color: color.black,
		width: '40%',
		position: 'absolute',
		left: '0',
		right: '0',
		margin: 'auto',
		borderRadius: '5px',
		marginTop: '10px',
		[theme.breakpoints.down('sm')]: {
			width: '80%'
		},
		[theme.breakpoints.up('md')]: {
			width: '40%'
		}
	},
	textField: {
		marginTop: '15px',
		border: `1px solid ${color.lightGray} !important`,
		borderRadius: '12px !important',
		backgroundColor: color.lightGray
	},
	selectBox: {
		padding: '3px',
		marginTop: '15px',
		textAlign: 'left',
		height: '2.6rem',
		marginLeft: 0,
		color: color.gray,
		border: `1px solid ${color.lightGray} !important`,
		borderRadius: '12px !important',
		backgroundColor: color.lightGray,
		cursor: 'pointer'
	},
	option: {
		cursor: 'pointer',
		padding: '4px',
		paddingLeft: '12px',
		color: color.gray
	},
	label: {
		marginTop: '20px',
		color: color.gray
	},
	cardHead: {
		backgroundColor: color.navBarBg,
		color: color.white
	},
	cardActions: {
		padding: '11px 10px 15px 10px',
		justifyContent: 'flex-end'
	},
	cancelButton: {
		backgroundColor: color.cancel,
		color: color.black,
		outline: color.gray,
		fontWeight: '400',
		fontSize: '14px',
		textTransform: 'capitalize',
		margin: '8px',
		letterSpacing: '1px',
		'&:hover': {
			backgroundColor: color.hoverCancel
		}
	},
	addButton: {
		color: color.white,
		backgroundColor: color.buttonBg,
		fontWeight: '400',
		letterSpacing: '1px',
		fontSize: '14px',
		textTransform: 'capitalize',
		margin: '8px',
		'&:hover': {
			backgroundColor: color.hoverButtonBg
		}
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddModal)
