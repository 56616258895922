/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import {IS_LOGIN, USER_DATA, LOCATION, PARENT_ACTIVE_PAGE} from '../constants'

export const storeLogin = (data) => {
	return {
		type: IS_LOGIN,
		data: data
	}
}
export const storeUser = (data) => {
	return {
		type: USER_DATA,
		data: data
	}
}
export const storeLngLat = (data) => {
	return {
		type: LOCATION,
		data: data
	}
}

export const parentPageActive = (data) => {
	return {
		type: PARENT_ACTIVE_PAGE,
		data: data
	}
}
