/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import {IS_LOGIN, USER_DATA, LOCATION, PARENT_ACTIVE_PAGE} from '../constants'
const initialState = {
	isLogin: false,
	userData: {},
	lngLat: {},
	initalPageActive: true
}

const dataForEditing = (state = initialState, action) => {
	switch (action.type) {
		case IS_LOGIN:
			return Object.assign({}, state, {
				...state,
				isLogin: action.data
			})
		case USER_DATA:
			return Object.assign({}, state, {
				userData: action.data,
				isLogin: true
			})
		case LOCATION:
			return Object.assign({}, state, {
				lngLat: action.data
			})
		case PARENT_ACTIVE_PAGE:
			return Object.assign({}, state, {
				initalPageActive: action.data
			})
		// break;
		default:
			return state
	}
}
export default dataForEditing
