/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {makeStyles, Typography, Grid, Button, TextField} from '@material-ui/core'
import {color, toastStyle} from '../assets/css/commonStyle'
import {toast} from 'react-toastify'
import {base_url} from '../config/config'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {connect} from 'react-redux'
import {storeLogin} from '../redux/actions/actions'
import {validate} from '../components/common/validate'
import SessionExpiredModal from '../components/common/SessionExpireModal'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const SubSettingsTab = (props) => {
	const classes = useStyles()

	const [settingTitle, setSettingTitle] = useState()
	const [session, setSession] = useState(true)

	useEffect(() => {
		getTitle()
	}, [])

	const handleCloseSessionModal = () => {
		getTitle()
		setSession(true)
	}

	const getTitle = async () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			await axios
				.get(`${base_url}/admin/settings/sub_setting/view_sub_settings`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('auth_token')}`
					}
				})
				.then((response) => {
					if (response.data.success) {
						setSettingTitle(response.data.data.subsettings[0].name)
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					return error
				})
		}
	}

	const addSetting = async () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			if (settingTitle.length === 0) {
				toast.error('Name is required.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else
				await axios
					.post(
						`${base_url}/admin/settings/sub_setting/add`,
						{
							name: settingTitle
						},
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem('auth_token')}`
							}
						}
					)
					.then((response) => {
						if (response.data.success) {
							toast.success('Setting added successfully.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
							getTitle()
						} else {
							toast.error('Setting already exist.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
						}
					})
					.catch(function (error) {
						toast.error('Something went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						return error
					})
		}
	}

	return (
		<div className={classes.root}>
			{session ? (
				<div>
					<div className={classes.contentContainer}>
						<Grid container>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<Typography variant="body1" className={classes.titleText}>
									Site Title
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<TextField type="text" size="small" className={classes.textField} id="textField" value={settingTitle} fullwidth variant="outlined" onChange={(e) => setSettingTitle(e.target.value)} />
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} className={classes.buttoncontainer}>
								<Button variant="contained" className={classes.cancelButton} size="small" onClick={() => alert('todo')}>
									Cancel
								</Button>
								<Button variant="contained" className={classes.saveButton} size="small" onClick={addSetting}>
									Save Changes
								</Button>
							</Grid>
						</Grid>
					</div>
					<table className={classes.table}></table>
				</div>
			) : (
				<SessionExpiredModal closeCB={handleCloseSessionModal} />
			)}
		</div>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		height: '100%',
		flexGrow: 1,
		backgroundColor: color.pagesBg,
		padding: '2rem'
	},
	item: {
		marginTop: '1rem'
	},
	textField: {
		width: '100%',
		border: `1px solid ${color.lightGray} !important`,
		borderRadius: '12px !important',
		height: '44px'
	},
	contentContainer: {
		backgroundColor: color.white,
		borderRadius: '12px',
		padding: '32px',
		gap: '40px'
	},
	titleText: {
		height: '60px',
		fontSize: '16px',
		fontWeight: '700'
	},
	buttoncontainer: {
		display: 'flex',
		justifyContent: 'end',
		marginTop: '2rem'
	},
	cancelButton: {
		width: '140px',
		backgroundColor: '#F0F0F0',
		color: color.black,
		outline: color.gray,
		fontWeight: '400',
		fontSize: '14px',
		textTransform: 'capitalize',
		margin: '8px',
		padding: '8px 20px',
		letterSpacing: '1px',
		border: 'none',
		borderRadius: '1rem',
		'&:hover': {
			backgroundColor: color.lightGray
		}
	},
	saveButton: {
		width: '150px',
		color: color.white,
		backgroundColor: color.buttonBg,
		fontWeight: '400',
		fontSize: '14px',
		textTransform: 'capitalize',
		letterSpacing: '1px',
		margin: '8px',
		gap: '8px',
		padding: '8px 20px',
		border: 'none',
		borderRadius: '1rem',
		'&:hover': {
			backgroundColor: color.hoverButtonBg
		}
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SubSettingsTab)
