/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useEffect, useState} from 'react'
import {Button, makeStyles, Modal, Card, CardHeader, CardContent, CardActions, Typography} from '@material-ui/core'
import {color, toastStyle} from '../../assets/css/commonStyle'
import {base_url} from '../../config/config'
import {toast} from 'react-toastify'
import {connect} from 'react-redux'
import {storeLogin} from '../../redux/actions/actions'
import {useHistory} from 'react-router'
import axios from 'axios'

const SessionModal = (props) => {
	const classes = useStyles()
	let _history = useHistory()

	const handleRenewSession = async () => {
		await axios
			.post(`${base_url}/admin/renew_session`, {
				email: props.store.userData.admin.email,
				password: props.store.userData.admin.password
			})
			.then((response) => {
				if (response.data.success) {
					toast.success('Session Restarted.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					localStorage.setItem('auth_token', response.data.data.admin.token)
					props.closeCB()
				} else if (response.data.message === 'Unable to renew the session') {
					toast.error(true, 'There is a problem renewing login session. Please try again, or contact an administrator.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
				}
			})
			.catch(function (error) {
				toast.error(false, 'There is a problem renewing login session. Please try again, or contact an administrator.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				console.log(error)
			})
	}

	const handleTerminateSession = () => {
		props.loginHandler(false)
		_history.push('/login')
		window.location.reload(false)
		localStorage.removeItem('auth_token')
		localStorage.clear()
	}

	return (
		<div className={classes.modal}>
			<Modal open={true} onClose={handleTerminateSession} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
				<div className={classes.paper}>
					<Card>
						<CardHeader title="Session Expired" className={classes.cardHead} />
						<CardContent>
							<Typography>Your login session has expired. Do you want to continue?</Typography>
						</CardContent>
						<CardActions className={classes.cardActions}>
							<Button variant="outlined" className={classes.cancelButton} size="small" onClick={handleTerminateSession}>
								NO
							</Button>
							<Button variant="contained" className={classes.addButton} size="small" onClick={handleRenewSession}>
								YES
							</Button>
						</CardActions>
					</Card>
				</div>
			</Modal>
		</div>
	)
}

const useStyles = makeStyles((theme) => ({
	paper: {
		textAlign: 'center',
		color: color.black,
		width: '40%',
		position: 'absolute',
		left: '0',
		right: '0',
		margin: 'auto',
		borderRadius: '5px',
		marginTop: '10px',
		[theme.breakpoints.down('md')]: {
			width: '80%'
		},
		[theme.breakpoints.up('md')]: {
			width: '40%'
		}
	},
	modal: {},
	checkbox: {
		color: color.primary,
		'&:hover': {
			backgroundColor: color.lightGray
		}
	},
	cardHead: {
		backgroundColor: color.navBarBg,
		color: color.white
	},
	cardActions: {
		padding: '11px 10px 15px 10px',
		justifyContent: 'flex-end'
	},
	cancelButton: {
		backgroundColor: color.cancel,
		color: color.black,
		outline: color.gray,
		fontWeight: '400',
		fontSize: '14px',
		textTransform: 'capitalize',
		margin: '8px',
		letterSpacing: '1px',
		'&:hover': {
			backgroundColor: color.hoverCancel
		}
	},
	addButton: {
		color: color.white,
		backgroundColor: color.buttonBg,
		fontWeight: '400',
		letterSpacing: '1px',
		fontSize: '14px',
		textTransform: 'capitalize',
		margin: '8px',
		'&:hover': {
			backgroundColor: color.hoverButtonBg
		}
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SessionModal)
