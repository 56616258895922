/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import {combineReducers} from 'redux'
import OKCStore from './reducers'

export default combineReducers({
	OKCStore
})
