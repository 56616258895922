/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React from 'react'
import {makeStyles, Typography, Paper} from '@material-ui/core'
import {color} from '../../assets/css/commonStyle'

const Card = ({cardName, cardData, cardColor}) => {
	const classes = useStyles()

	return (
		<Paper className={classes.card} style={{backgroundColor: cardColor}}>
			<Typography variant="h6" className={classes.cardHeading}>
				{cardName}
			</Typography>
			<Typography variant="h4" className={classes.cardData}>
				{cardData}
			</Typography>
		</Paper>
	)
}

const useStyles = makeStyles((theme) => ({
	card: {
		backgroundColor: 'red',
		padding: theme.spacing(3),
		color: color.white
	},
	cardHeading: {
		fontWeight: 'bold'
	},
	cardData: {
		marginTop: '10px'
	}
}))
export default Card
