/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect, useRef } from 'react'
import {useHistory} from 'react-router-dom'
import axios from 'axios'
import {makeStyles, Typography, Avatar, Grid, Divider, Box, Button} from '@material-ui/core'
import {AddCircle, Edit, Grade} from '@material-ui/icons'
import {color, toastStyle} from '../assets/css/commonStyle'
import {toast} from 'react-toastify'
import {MDBDataTableV5} from 'mdbreact'
import {MDBSpinner} from 'mdb-react-ui-kit'
import {base_url} from '../config/config'
import Pagination from '../components/common/Pagination'
import NewEditAsset from '../components/updatePages/NewEditAsset'
import {connect} from 'react-redux'
import {storeLogin, parentPageActive} from '../redux/actions/actions'
import {validate} from '../components/common/validate'
import SessionExpiredModal from '../components/common/SessionExpireModal'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const LiveAssets = (props) => {
	const classes = useStyles()

	const [updateData, setUpdateData] = useState('')

	const [openScreen, setOpenScreen] = useState(false)

	const [currentPage, setCurrentPage] = useState(1)
	const [length, setLength] = useState(0)
	const [spinner, setSpinner] = useState(false)
	const [session, setSession] = useState(true)
	const [search, setSearch] = useState('')
	const [filteredAssets, setFilteredAssets] = useState([])
	const [allAssets, setAllAssets] = useState([])
	const searchRef = useRef()
	
	const [assets, setAssets] = useState({
		columns: [
			{
				label: 'Name',
				field: 'name',
				width: 1000,
				attributes: {'aria-controls': 'DataTable', 'aria-label': 'Name'}
			},
			{label: 'Enabled', field: 'enabled', type: 'checkbox', width: 40},

			{label: '', field: 'edit', sort: String('disabled'), width: 40}
		],
		rows: []
	})

	useEffect(() => {
		if (allAssets.length === 0) {
			console.log('load all assets')
			viewAssets(currentPage)
		}
	}, [])

	useEffect(() => {
		updateSearch(search)
	}, [search])

	useEffect(() => {
		setAssets((preValue) => {
			return {
				columns: [...preValue.columns],
				rows: []
			}
		})
		filteredAssets.map((data) => {
			createTable(data)
		})
		console.log(filteredAssets)

	}, [filteredAssets])

	const updateSearch = (value) => {
		// console.log(value)

		if (value === '') {
			viewAssets(0)
		} else {
			viewAssets(99999)
			setFilteredAssets(allAssets.filter((data) => {
				console.log(data.name.toLowerCase(),value.toLowerCase())
                return data.name.toLowerCase().includes(value.toLowerCase())
            }))
        }
	}

	const updateSearchValue = (event) => {
		setSearch(event.target.value)
	}

	function getData(page) {
		viewAssets(page)
		setCurrentPage(page)
	}

	const openNewPage = () => {
		setOpenScreen(true)
		props.parentPageHandler(false)
	}

	const openEditPage = (row) => {
		setUpdateData(row)
		setOpenScreen(true)
		props.parentPageHandler(false)
	}

	const goBack = () => {
		setOpenScreen(false)
		setUpdateData('')
		viewAssets()
		props.parentPageHandler(true)
	}

	const handleCloseSessionModal = () => {
		viewAssets()
		setSession(true)
	}

	const setTheAssets = (assets) => {
		setAllAssets(assets)
		if (filteredAssets.length === 0) {
			setFilteredAssets(assets)
		}
	}

	const viewAssets = async (page) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			setSpinner(true)
			await axios
				.post(
					`${base_url}/admin/live_assets/view_live_assets`,
					{
						page: page
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('auth_token')}`
						}
					}
				)
				.then((response) => {
					if (response.data.success) {
						setSpinner(false)
						setLength(response.data.data.assets.length)
						setTheAssets(response.data.data.assets)
					} else {
						setSpinner(false)
					}
				})
				.catch(function (error) {
					setSpinner(false)
					toast.error('Something went wrong in fetching live assets data. Please try again later.', {position: toastStyle.position, autoClose: toastStyle.closeDuration})
					return error
				})
		}
	}

	const handleEnable = async (row) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			await axios
				.post(
					`${base_url}/admin/live_assets/update`,
					{
						...row,
						id: row._id,
						enabled: !row.enabled
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('auth_token')}`
						}
					}
				)
				.then((response) => {
					console.log(response)
					if (response.data.success) {
						toast.success('Enable status updated successfully.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						viewAssets()
					} else {
						toast.error('Something went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					return error
				})
		}
	}

	const createTable = (row) => {
		let _obj = {
			name: row.name,
			enabled: (
				<label className="switch">
					<input type="checkbox" onChange={() => handleEnable(row)} checked={row.enabled} />
					<span className={`slider round`}></span>
				</label>
			),
			edit: (
				<Avatar variant="square" className={classes.editButton}>
					<Edit
						className="editIcon"
						onClick={() => {
							openEditPage(row)
						}}
					/>
				</Avatar>
			)
		}
		setAssets((preValue) => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	const clearSearch = () => {
		setFilteredAssets([])
		searchRef.current.value = '';
		viewAssets(0)
	}

	return (
		<div>
			{session ? (
				<div>
					{props.store.initalPageActive === true ? (
						<div className={classes.root}>
							<Grid container>
								<Grid item xs={12} sm={12} md={11} lg={11}>
									<Typography variant="h4" className={classes.heading}>
										Live Assets
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={1} lg={1}>
									<Button variant="contained" onClick={openNewPage} className={classes.button} startIcon={<AddCircle className="addNewIcon" style={{fontSize: '14px'}} />}>
										<Box style={{paddingTop: '1px'}}>New</Box>
									</Button>
								</Grid>
								<Grid item xs={12} sm={12} md={1} lg={1}>
									<input type="text" ref={searchRef} placeholder="Type to Search" defaultValue={search} onChange={evt => updateSearchValue(evt)} />
									<Button variant="contained" onClick={clearSearch} className={classes.button}>Clear Search</Button>
								</Grid>
							</Grid>
							<Divider variant="fullWidth" className={classes.divider} />
							{spinner ? (
								<div className={classes.Flex}>
									<MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner>
								</div>
							) : (
								<div id="liveassets">
									<MDBDataTableV5 className={classes.table} hover entries={25} data={assets} fullPagination={false} paging={false} info={false} searchTop={false} searchBottom={false} />
									{search != '' ? (<Pagination viewCB={getData} len={length} page={currentPage} />) : ('')}
								</div>
							)}
						</div>
					) : (
						<div>{updateData !== '' || updateData.name !== undefined ? <NewEditAsset updateData={updateData} goBackCB={goBack} setOpenScreenCB={setOpenScreen} /> : <NewEditAsset goBackCB={goBack} setOpenScreenCB={setOpenScreen} />}</div>
					)}{' '}
				</div>
			) : (
				<SessionExpiredModal closeCB={handleCloseSessionModal} />
			)}
		</div>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		height: '100%',
		flexGrow: 1,
		backgroundColor: color.pagesBg,
		padding: '2rem'
	},
	button: {
		backgroundColor: color.buttonBg,
		color: color.white,
		textTransform: 'capitalize',
		fontSize: '14px',
		margin: '8px',
		padding: '8px 20px',
		'&:hover': {
			backgroundColor: color.hoverButtonBg
		}
	},
	table: {
		marginTop: '20px'
	},
	heading: {
		fontWeight: '600',
		fontSize: '36px',
		color: color.black
	},
	divider: {
		margin: '20px 0px 20px 0px'
	},
	editButton: {
		backgroundColor: 'transparent',
		width: '30px',
		height: '30px',
		cursor: 'pointer',
		borderRadius: '0.15rem',
		'&:hover': {}
	},
	icon: {
		fontSize: '22px !important'
	},
	spinnerStyle: {
		color: color.seaGreen,
		width: '60px',
		height: '60px',
		marginTop: '4%'
	},
	Flex: {
		display: 'flex',
		justifyContent: 'center'
	},
	checkBoxInput: {}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data)),
	parentPageHandler: (data) => dispatch(parentPageActive(data))
})


export default connect(mapStateToProps, mapDispatchToProps)(LiveAssets)
