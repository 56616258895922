import mapboxgl from '!mapbox-gl'
import dotenv from 'dotenv'
dotenv.config()
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

export const generateMap = async (lng, lat, setLongitude, setLatitude, data) => {
	let center = ''
	let currentMarkers = []

	const map = new mapboxgl.Map({
		container: 'mapContainer',
		style: 'mapbox://styles/mapbox/streets-v11',
		zoom: 14
	})

	if (data !== undefined) {
		if (data.longitude !== null && data.latitude !== null) {
			if ((data.longitude.includes('W') || data.longitude.includes('E')) && data.longitude.includes('.')) {
				center = [parseFloat(data.longitude) * -1, parseFloat(data.latitude)]
			} else if (data.longitude.includes('W') || data.longitude.includes('E')) {
				center = [ParseDMS(data.longitude.concat(data.latitude))]
			} else {
				center = [data.longitude, data.latitude]
			}

			map.setCenter(center)

			map.flyTo({
				center: center
			})

			const itemDiv = createDivElement()
			const textElement = createTextElement()
			itemDiv.appendChild(textElement)

			const marker = new mapboxgl.Marker(itemDiv, {}).setLngLat(center).addTo(map)

			if (currentMarkers.length > 0) {
				for (let i = 0; i < currentMarkers.length; i++) {
					console.log(currentMarkers[i])
					currentMarkers[i].remove()
				}
			}
			currentMarkers.push(marker)
			marker.on('dragend', async () => {})
		}
	} else {
		center = [lng, lat]
		map.setCenter(center)
		map.flyTo({
			center: center
		})
	}

	const marker = new mapboxgl.Marker({}).setLngLat(center).addTo(map)

	map.on('style.load', function () {
		map.on('click', function (e) {
			let coordinates = e.lngLat
			let coords = document.getElementById('coordinates')
			coords.style.display = 'block'
			coords.innerHTML = `Longitude: ${coordinates.lng}<br />Latitude: ${coordinates.lat}`
			setLongitude(coordinates.lng)
			setLatitude(coordinates.lat)
			const newMarker = new mapboxgl.Marker().setLngLat([coordinates.lng, coordinates.lat]).addTo(map)
			if (currentMarkers.length > 0) {
				for (let i = 0; i < currentMarkers.length; i++) {
					currentMarkers[i].remove()
				}
			}
			currentMarkers.push(newMarker)
		})
	})
}

function ParseDMS(input) {
	var parts = input.split(/[^\d\w]+/)
	var lng = ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3])
	var lat = ConvertDMSToDD(parts[4], parts[5], parts[6], parts[7])
	return {lng: lng, lat: lat}
}

function ConvertDMSToDD(days, minutes, seconds, direction) {
	var dd = parseFloat(days) + parseFloat(minutes / 60) + parseFloat(seconds / (60 * 60))
	if (direction === 'S' || direction === 'W') {
		dd *= -1
	}
	return dd
}

const createDivElement = () => {
	const width = 3
	const height = 3
	const itemDiv = document.createElement('div')
	itemDiv.style.padding = 0
	itemDiv.style.position = 'absolute'
	itemDiv.style.border = 'none'
	itemDiv.style.borderRadius = '50%'
	itemDiv.style.cursor = 'pointer'
	itemDiv.style.color = 'white'
	itemDiv.style.width = `${width}rem`
	itemDiv.style.height = `${height}rem`
	itemDiv.style.backgroundSize = '100%'
	itemDiv.style.backgroundColor = 'transparent'
	itemDiv.style.textAlign = 'center'
	itemDiv.style.display = 'block'
	return itemDiv
}

const createTextElement = () => {
	const textEl = document.createElement('div')
	textEl.style.position = 'absolute'
	textEl.style.top = '3rem'
	textEl.style.width = '3rem'
	textEl.style.fontSize = '0.8rem'
	textEl.style.fontWeight = 'bold'
	return textEl
}
