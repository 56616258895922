/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect, useRef} from 'react'
import {Button, makeStyles, Box, Typography, TextField, Grid} from '@material-ui/core'
import {Cancel} from '@material-ui/icons'
import {Editor} from 'react-draft-wysiwyg'
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {MDBSpinner} from 'mdb-react-ui-kit'
import proj4 from 'proj4'
import {toast} from 'react-toastify'
import axios from 'axios'
import {color, toastStyle} from '../../assets/css/commonStyle'
import {base_url, aws_url} from '../../config/config'
import DefaultImage from '../../assets/images/default-item.jpeg'
import mapboxgl from '!mapbox-gl'
import dotenv from 'dotenv'
import SessionExpiredModal from '../../components/common/SessionExpireModal'

dotenv.config()
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

const NewEditTrail = ({updateData, goBackCB, setOpenScreenCB, ...props}) => {
    const classes = useStyles()
    const [trailId, setTrailId] = useState('')
    const [trailName, setTrailName] = useState('')
    const [jsonFile, setJsonFile] = useState('')
    const [featuredImage, setFeaturedImage] = useState('')
    const [optionalImages, setOptionalImages] = useState([])
    const [isNewImgUpload, setIsNewImgUpload] = useState(false)
    const [isNewOptImgUpload, setIsNewOptImgUpload] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [buttonSpinner, setButtonSpinner] = useState(false)
    const [startPoint, setStartPoint] = useState([0,0])
    const [stopPoint, setStopPoint] = useState([0,0])
    const [placeStartPointButtonText, setPlaceStartPointButtonText] = useState('Place Start Point');
    const [placeStopPointButtonText, setPlaceStopPointButtonText] = useState('Place Stop Point');

    const map = useRef();

    const startPointMarker = useRef(false);
    const startPointLong = useRef(startPoint[0]);
    const startPointLat = useRef(startPoint[1]);
    const settingStartPoint = useRef(false);

    const stopPointMarker = useRef(false);
    const stopPointLong = useRef(stopPoint[0]);
    const stopPointLat = useRef(stopPoint[1]);
    const settingStopPoint = useRef(false);

    const [session, setSession] = useState(true)
    const [setDefaults, setSetDefaults] = useState(true)

    const [newJsonFile, setNewJsonFile] = useState(false)
    const errorMessage = 'Invalid image type. Please try again.'


    useEffect(() => {
        if (updateData !== undefined) {
            setTrailId(updateData._id)
            setTrailName(updateData.name)
            setJsonFile(updateData.jsonFile)
            startPointMarker.current = updateData.startPointMarker;
            stopPointMarker.current = updateData.stopPointMarker;
            setFeaturedImage(updateData.featuredImage)
            setOptionalImages(updateData.optionalImages)
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(updateData.content))))
        }
    }, [updateData])

    let data = ''
    useEffect(() => {
        if (jsonFile !== '' && jsonFile !== undefined) {
            if (updateData !== undefined && newJsonFile === false) {
                if (updateData.jsonFile !== null) {
                    let json = fetchData(`${aws_url}/${updateData.jsonFile}`).then((response) => {
                        return response
                    })
                    json.then(function (result) {
                        if (result !== undefined) {
                            generateMap(result.data)
                        }
                    })
                }
            } else if (updateData !== undefined && newJsonFile === true) {
                eval('data=' + jsonFile)
                setNewJsonFile(false)
                generateMap(data)
            } else {
                eval('data=' + jsonFile)
                setNewJsonFile(false)
                generateMap(data)
            }
        }
    }, [jsonFile])

    useEffect(() => {
        if (setDefaults) {
            setDefaultValues();
            setSetDefaults(false);
        }
    }, [setDefaults])

    const setDefaultValues = () => {
        settingStartPoint.current = false;
        settingStopPoint.current = false;
    }

    

    const fetchData = async (url) => {
        if (url.length > 45) {
            return await axios
                .get(url)
                .then((response) => {
                    return response
                })
                .catch(function (error) {
                    toast.error('Something went wrong in fetching trail json data. Please try again later.', {position: toastStyle.position, autoClose: toastStyle.closeDuration})
                    return error
                })
        }
    }

    const handleJsonFile = (e) => {
        if (e.target.files[0] && e.target.files[0].name.includes('geojson')) {
            const fileReader = new FileReader()
            fileReader.readAsText(e.target.files[0], 'UTF-8')
            fileReader.onload = (e) => {
                setJsonFile(e.target.result)
            }
            setNewJsonFile(true)
        } else {
            toast.error('Invalid file type. Please try again with ".geojson" file.', {position: toastStyle.position, autoClose: toastStyle.closeDuration})
            document.getElementsByTagName('input')[0].click()
        }
    }

    const validateImage = (arr) => {
        let isImage = false
        const allowedTypes = ['jpg', 'jpeg', 'png', 'svg', 'webp', 'gif']
        for (let i = 1; i < allowedTypes.length; i++) {
            if (arr.includes(allowedTypes[i])) {
                isImage = true
            }
        }
        return isImage
    }

    const removeFeaturedImage = () => {
        setFeaturedImage('')
        setIsNewImgUpload(false)
    }

    const addImage = ({target}, currentImage) => {
        const reader = new FileReader()
        if (target.files[0]) {
            reader.readAsDataURL(target.files[0])
        }
        reader.onload = () => {
            if (reader.readyState === 2) {
                const base64 = reader.result
                const isImage = validateImage(base64.substring(0, 30))
                if (isImage) {
                    if (currentImage === 'feature') {
                        setFeaturedImage(base64)
                        setIsNewImgUpload(true)
                    } else if (currentImage === 'optional') {
                        setOptionalImages((optImages) => {
                            return [...optImages, base64]
                        })
                        setIsNewOptImgUpload(true)
                    }
                } else {
                    toast.error(errorMessage, {position: toastStyle.position, autoClose: toastStyle.closeDuration})
                }
            }
        }
    }

    const removeOptionalImages = (item) => {
        const newImages = optionalImages.filter((image) => image !== item)
        setOptionalImages(newImages)
    }

    let lineCoords = [],
        multiLineCoords = []

    const togglePlaceStartPointButton = () => {
    	if (settingStartPoint.current) {
    		setPlaceStartPointButtonText('Place Start Point');
            settingStartPoint.current = false;
            map.current.getCanvas().style.cursor = ''
    	} else {
    		setPlaceStartPointButtonText('Lock In Start Point');
            settingStartPoint.current = true;
            map.current.getCanvas().style.cursor = 'default'
    	}
    }

    const togglePlaceStopPointButton = () => {
    	if (settingStopPoint.current) {
            setPlaceStopPointButtonText('Place Stop Point');
            settingStopPoint.current = false;
            map.current.getCanvas().style.cursor = ''
        } else {
            setPlaceStopPointButtonText('Lock In Stop Point');
            settingStopPoint.current = true;
            map.current.getCanvas().style.cursor = 'default'
        }
    }



    const updateStartStopPoint = (e) => {
    	let coordinates = e.lngLat
        
    	if (settingStartPoint.current) {
    		if (!startPointMarker.current) {
    			const newMarker = new mapboxgl.Marker({ color: 'green'}).setLngLat([coordinates.lng, coordinates.lat]).addTo(map.current)
    			startPointMarker.current = newMarker;
    		} else {
    			startPointMarker.current.remove();
    			const newMarker = new mapboxgl.Marker({ color: 'green'}).setLngLat([coordinates.lng, coordinates.lat]).addTo(map.current)
    			startPointMarker.current = newMarker;
    		}
    	}
        if (settingStopPoint.current) {
            if (!stopPointMarker.current) {
                const newMarker = new mapboxgl.Marker({ color: 'red'}).setLngLat([coordinates.lng, coordinates.lat]).addTo(map.current)
                stopPointMarker.current = newMarker;
            } else {
                stopPointMarker.current.remove();
                const newMarker = new mapboxgl.Marker({ color: 'red'}).setLngLat([coordinates.lng, coordinates.lat]).addTo(map.current)
                stopPointMarker.current = newMarker;
            }
        }

    }

    let center = ''
    const generateMap = (data) => {
        let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'
        let utm = 'PROJCS["NAD83 / Oklahoma",GEOGCS["CRS84",DATUM["NAD83_National_Spatial_Reference_System_2007", SPHEROID["GRS 1980",6378137,298.257222101, AUTHORITY["EPSG","7019"]], TOWGS84[0,0,0,0,0,0,0], AUTHORITY["EPSG","6759"]], PRIMEM["Greenwich",0, AUTHORITY["EPSG","8901"]], UNIT["degree",0.0174532925199433, AUTHORITY["EPSG","9122"]], AUTHORITY["EPSG","4759"]], PROJECTION["Lambert_Conformal_Conic_2SP"], PARAMETER["standard_parallel_1",35.57], PARAMETER["standard_parallel_2",46], PARAMETER["latitude_of_origin",35.481918], PARAMETER["central_meridian",-113.69], PARAMETER["false_easting",600000], PARAMETER["false_northing",0], UNIT["metre",1, AUTHORITY["EPSG","9001"]], AXIS["X",EAST], AXIS["Y",NORTH], AUTHORITY["EPSG","3639"]]'

        if (data) {
            let coooords = []
            let center = []

            if(data.features[0].geometry.type == 'MultiLineString'){
                center = data.features[0].geometry.coordinates[0][0]
                coooords = data.features[0].geometry.coordinates
            } else if (data.features[0].geometry.type == 'LineString') {
                center = data.features[0].geometry.coordinates[0]
                coooords = [data.features[0].geometry.coordinates]
            } else {
                console.log("Invalid File Geometry Type");
            }

            // let i = 3

            let newMap = new mapboxgl.Map({
                container: 'mapContainer',
                style: 'mapbox://styles/mapbox/streets-v11',
                zoom: 12,
                center: center
            })
            newMap.flyTo({
                center: center
            })

            for (let i = 0; i < data.features.length; i++) {
                
                // new mapboxgl.Marker().setLngLat(center).addTo(map)
                
                let coords = data.features[i].geometry.coordinates;
                let type = data.features[i].geometry.type;

                newMap.on('load', () => {
                    newMap.addSource(`route-${i}`, {
                        type: 'geojson',
                        data: {
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: type,
                                coordinates: coords
                            }
                        }
                    })

                    newMap.addLayer({
                        id: `outline-${i}`,
                        type: 'line',
                        source: `route-${i}`,
                        layout: {},
                        paint: {
                            'line-color': '#ccc',
                            'line-width': 9
                        }
                    })

                    newMap.addLayer({
                        id: `route-${i}`,
                        type: 'line',
                        source: `route-${i}`,
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        paint: {
                            'line-color': '#669b1e',
                            'line-width': 8
                        }
                    })

                })

            }

            if (startPointMarker.current) {
                if (startPointMarker.current._lngLat) {
                    startPointMarker.current = new mapboxgl.Marker({'color': 'green'}).setLngLat(startPointMarker.current._lngLat).addTo(newMap)
                } else {
                    startPointMarker.current = new mapboxgl.Marker({'color': 'green'}).setLngLat(startPointMarker.current).addTo(newMap)
                }
            }

            if (stopPointMarker.current) {
                if (stopPointMarker.current._lngLat) {
                    stopPointMarker.current = new mapboxgl.Marker({'color': 'green'}).setLngLat(stopPointMarker.current._lngLat).addTo(newMap)
                } else {
                    stopPointMarker.current = new mapboxgl.Marker({'color': 'green'}).setLngLat(stopPointMarker.current).addTo(newMap)
                }
            }

            newMap.on('click', function (e) {
                updateStartStopPoint(e);
            })

            map.current = newMap;
        }
    }

    const updateTrail = async () => {
        let convertedContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        if (trailName.length === 0) {
            toast.error('Trail name is required.', {
                position: toastStyle.position,
                autoClose: toastStyle.closeDuration
            })
        } else {
            setButtonSpinner(true)
            await axios
                .post(
                    `${base_url}/admin/trails/update`,
                    {
                        id: trailId,
                        name: trailName,
                        jsfile: jsonFile,
                        fImage: featuredImage,
                        optImages: optionalImages,
                        content: convertedContent,
                        startPointMarker: startPointMarker.current._lngLat,
                        stopPointMarker: stopPointMarker.current._lngLat,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('auth_token')}`
                        }
                    }
                )
                .then((response) => {
                    if (response.data.success) {
                        toast.success('Trail updated successfully.', {
                            position: toastStyle.position,
                            autoClose: toastStyle.closeDuration
                        })
                        setOpenScreenCB(false)
                        setButtonSpinner(false)
                        goBackCB()
                    } else {
                        toast.error('Something went wrong. Please try again later.', {
                            position: toastStyle.position,
                            autoClose: toastStyle.closeDuration
                        })
                    }
                })
                .catch(function (error) {
                    toast.error('Something went wrong. Please try again later.', {
                        position: toastStyle.position,
                        autoClose: toastStyle.closeDuration
                    })
                })
        }
    }

    const addNewTrail = async () => {
        let convertedContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        if (trailName.length === 0) {
            toast.error('Trail name is required.', {
                position: toastStyle.position,
                autoClose: toastStyle.closeDuration
            })
        } else {
            setButtonSpinner(true)
            await axios
                .post(
                    `${base_url}/admin/trails/add`,
                    {
                        name: trailName,
                        jsonfile: jsonFile,
                        featuredImage: featuredImage,
                        optionalImages: optionalImages,
                        content: convertedContent,
                        startPoint: startPointMarker.current._lngLat,
                        stopPoint: stopPointMarker.current._lngLat,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('auth_token')}`
                        }
                    }
                )
                .then((response) => {
                    if (response.data.success) {
                        toast.success('Trail added successfully.', {
                            position: toastStyle.position,
                            autoClose: toastStyle.closeDuration
                        })
                        setButtonSpinner(false)
                        setOpenScreenCB(false)
                        goBackCB()
                    } else if (response.data.message === 'Trail already exist') {
                        toast.error('Trail already exist.', {
                            position: toastStyle.position,
                            autoClose: toastStyle.closeDuration
                        })
                        setButtonSpinner(false)
                    } else {
                        toast.error('Trail could not be added.', {
                            position: toastStyle.position,
                            autoClose: toastStyle.closeDuration
                        })
                        setButtonSpinner(false)
                    }
                })
                .catch(function (error) {
                    toast.error('Something went wrong. Please try again later.', {
                        position: toastStyle.position,
                        autoClose: toastStyle.closeDuration
                    })
                    return error
                })
        }
    }

    let wordCount = 0
    if (convertToRaw(editorState.getCurrentContent())?.blocks?.length > 0) {
        for (const block of convertToRaw(editorState.getCurrentContent())?.blocks) {
            wordCount += block.text.length
        }
    }

    return (
        <div className={classes.root}>
            <Grid item container className={classes.items} xs={12} sm={12} md={12} lg={8}>
                <Typography variant="h4" className={classes.heading}>
                    New/Edit Trail
                </Typography>
                {/* <Button variant="outlined" className={classes.cancelButton} startIcon={<ArrowBack style={{fontSize: '14px'}} />} size="small" onClick={goBackCB}>
						Go back
					</Button> */}
                <Box className={classes.detailsContainer}>
                    <Typography variant="body1" className={classes.textLabel}>
                        Trail Name
                    </Typography>
                    <TextField type="text" value={trailName} size="small" className={classes.textField} variant="outlined" fullWidth onChange={(e) => setTrailName(e.target.value)} />
                    <Typography variant="body1" className={classes.textLabel}>
                        Trail GeoJSON
                    </Typography>
                    <label className={classes.inputLabel} htmlFor="geojsonfile">
                        Upload File
                    </label>
                    <TextField type="file" size="small" className={classes.inputFile} id="geojsonfile" onChange={handleJsonFile} />
                    <Typography variant="body1" className={classes.textLabel}>
                        Start Point
                    </Typography>
                    <Grid item container spacing={2} xs={12} sm={12} md={12} lg={12} variant="body1" className={classes.locationContainer}>
                        <Grid item container xs={12} sm={12} md={4} lg={4}>
                            <Grid item className={classes.textLeft} xs={12} sm={12} md={12} lg={12}>
                                Longitude
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField type="text" value={startPoint[0]} ref={startPointLong} size="small" className={classes.smallTextField} variant="outlined" fullWidth onChange={(e) => setStartPointLongitude(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={12} md={4} lg={4}>
                            <Grid item className={classes.textLeft} xs={12} sm={12} md={12} lg={12}>
                                Latitude
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField type="text" value={startPoint[1]} ref={startPointLat} size="small" className={classes.smallTextField} variant="outlined" fullWidth onChange={(e) => setStartPointLatitude(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid className={classes.btnAlign} item xs={12} sm={12} md={6} lg={6}>
                            <Button variant="contained" className={classes.setLocationButton} size="small" onClick={() => togglePlaceStartPointButton()}>
                                {placeStartPointButtonText}
                            </Button>
                        </Grid>
                    </Grid>
                    <Typography variant="body1" className={classes.textLabel}>
                        Stop Point
                    </Typography>
                    <Grid item container spacing={2} xs={12} sm={12} md={12} lg={12} variant="body1" className={classes.locationContainer}>
                        <Grid item container xs={12} sm={12} md={4} lg={4}>
                            <Grid item className={classes.textLeft} xs={12} sm={12} md={12} lg={12}>
                                Longitude
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField type="text" value={stopPoint[0]} size="small" className={classes.smallTextField} variant="outlined" fullWidth onChange={(e) => setStopPointLongitude(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={12} md={4} lg={4}>
                            <Grid item className={classes.textLeft} xs={12} sm={12} md={12} lg={12}>
                                Latitude
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField type="text" value={stopPoint[1]} size="small" className={classes.smallTextField} variant="outlined" fullWidth onChange={(e) => setStopPointLatitude(e.target.value)} />
                            </Grid>
                        </Grid>
                        <Grid className={classes.btnAlign} item xs={12} sm={12} md={6} lg={6}>
                            <Button variant="contained" className={classes.setLocationButton} size="small" onClick={() => togglePlaceStopPointButton()}>
                                {placeStopPointButtonText}
                            </Button>
                        </Grid>
                    </Grid>
                    <Box className={classes.imageBox}>
                        {jsonFile ? (
                            <div id="mapContainer" style={{width: '100%', height: '100%', padding: 0, margin: 0, borderRadius: '0.4rem'}}></div>
                        ) : (
                            <label className={classes.noFileLabel} htmlFor="geojsonfile">
                                Choose a file to upload
                            </label>
                        )}
                    </Box>
                    <Typography variant="body1" className={classes.textLabel}>
                        Featured Image
                    </Typography>
                    <Grid item xs={12} sm={12} md={8} lg={8} className={classes.featuredImageBox}>
                        <img src={featuredImage ? (isNewImgUpload ? featuredImage : `${aws_url}/${featuredImage}`) : DefaultImage} className={classes.featuredImage} />
                        {featuredImage ? (
                            <Button variant="outlined" className={classes.deletebutton} size="small" onClick={removeFeaturedImage}>
                                <Cancel className={classes.delete} fontSize="small" />
                            </Button>
                        ) : (
                            <div></div>
                        )}
                    </Grid>
                    <label htmlFor="upload2" className={classes.inputLabel} aria-label="upload picture">
                        Upload Featured Image
                    </label>
                    <input type="file" size="small" className={classes.uploadFile} id="upload2" onChange={(e) => addImage(e, 'feature')} />
                    <Typography variant="body1" className={classes.textLabel}>
                        Additional Images (optional)
                    </Typography>
                    <Grid item container spacing={2} xs={12} sm={12} md={12} lg={12} className={classes.additionalImageBox}>
                        {optionalImages &&
                            optionalImages.map((item, index) => {
                                return (
                                    <Grid item key={index} xs={6} sm={6} md={4} lg={4} className={classes.additionalImageBox}>
                                        <img key={index} src={item ? (item.startsWith('data:image') ? `${item}` : `${aws_url}/${item}`) : DefaultImage} className={classes.additionalImage} />
                                        <Button className={classes.deletebuttonSmall} size="small" onClick={() => removeOptionalImages(item)}>
                                            <Cancel className={classes.deleteSmall} fontSize="xs" />
                                        </Button>
                                    </Grid>
                                )
                            })}
                    </Grid>
                    <label htmlFor="upload3" className={classes.inputLabel} aria-label="upload picture">
                        Upload Optional Image
                    </label>
                    <input type="file" size="small" className={classes.uploadFile} id="upload3" onChange={(e) => addImage(e, 'optional')} />
                    <Typography variant="body1" className={classes.textLabel}>
                        Trail Information Content
                    </Typography>
                    <Typography variant="body1" className={classes.additionalContentDescription}>
                        {wordCount <= 1500 ? wordCount : 1500} of 1500 words
                    </Typography>
                    <Editor editorState={editorState} value={editorState} onEditorStateChange={setEditorState} wrapperClassName={classes.wrapperClass} editorClassName={classes.editorClass} toolbarClassName={classes.toolbarClass} />
                    <div className={classes.buttonContainer}>
                        <Button variant="outlined" className={classes.cancelButton} size="small" onClick={goBackCB}>
                            Cancel
                        </Button>
                        {buttonSpinner & (updateData !== undefined) ? (
                            <Button variant="contained" className={classes.saveButton} size="small">
                                <MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner>
                            </Button>
                        ) : !buttonSpinner & (updateData !== undefined) ? (
                            <Button variant="contained" className={classes.saveButton} size="small" onClick={updateTrail}>
                                Save Changes
                            </Button>
                        ) : buttonSpinner & (updateData === undefined) ? (
                            <Button variant="contained" className={classes.saveButton} size="small">
                                <MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner>
                            </Button>
                        ) : (
                            <Button variant="contained" className={classes.saveButton} size="small" onClick={addNewTrail}>
                                Save Changes
                            </Button>
                        )}
                    </div>
                </Box>
            </Grid>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: `${color.pagesBg} !important`,
        display: 'flex',
        justifyContent: 'center'
    },
    detailsContainer: {
        backgroundColor: '#fff',
        borderRadius: '12px',
        padding: '32px',
        gap: '40px'
    },
    heading: {
        margin: '1rem 0',
        fontFamily: 'ZillaSlab-Bold'
    },
    modal: {},
    textField: {
        color: color.gray,
        borderRadius: '12px !important',
        padding: '8px 16px',
        gap: '12px',
        border: `2px solid ${color.newBorder}`,
        flex: 'none',
        order: 1,
        alignSelf: 'stretch',
        flexGrow: 0
    },
    textLabel: {marginTop: '1rem', marginBottom: '0.4rem'},
    featuredImageBox: {
        height: '16rem',
        borderRadius: '0.6rem'
    },
    featuredImage: {
        borderRadius: '0.6rem',
        border: '1.5px solid gray',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    additionalImageBox: {
        borderRadius: '0.4rem'
    },
    additionalImage: {
        width: '100%',
        height: '8rem',
        borderRadius: '0.4rem',
        border: '1px solid gray',
        objectFit: 'cover'
    },
    deletebutton: {
        backgroundColor: 'transparent',
        color: color.darkGray,
        position: 'absolute',
        marginLeft: '-3.6rem',
        marginTop: '-0.2rem',
        width: '0.1rem',
        border: 'none'
    },
    delete: {
        width: '100%',
        height: '100%',
        transform: 'scale(0.6)',
        '&:hover': {
            color: color.gray
        }
    },
    deletebuttonSmall: {
        backgroundColor: 'transparent !important',
        color: color.darkGray,
        position: 'absolute',
        marginLeft: '-3.6rem',
        marginTop: '-0.8rem',
        width: '0.1rem',
        border: 'none'
    },
    deleteSmall: {
        width: '100%',
        height: '100%',
        transform: 'scale(0.4)',
        '&:hover': {
            color: color.gray,
            backgroundColor: 'transparent !important'
        }
    },
    wrapperClass: {
        border: `1px solid ${color.newBorder}`,
        borderRadius: '0.5rem',
        padding: '0.2rem'
    },
    toolbarClass: {
        display: 'none',
        border: `1px solid  ${color.newBorder}`
    },
    editorClass: {
        padding: '0.1rem',
        color: color.darkGray,
        height: '12rem'
    },
    uploadFile: {opacity: 0},
    inputLabel: {
        backgroundColor: color.primary,
        color: color.white,
        borderRadius: '4px',
        marginTop: '1rem',
        fontSize: '14px',
        padding: '8px 20px',
        letterSpacing: '1px',
        '&:hover': {
            backgroundColor: color.hoverPrimary
        }
    },

    inputFile: {
        backgroundColor: color.primary,
        textTransform: 'capitalize',
        paddingBottom: '1rem',
        borderRadius: '5px',
        display: 'none'
    },
    imageBox: {height: '23rem', objectFit: 'contain', backgroundColor: color.golden, marginTop: '1rem', borderRadius: '0.4rem', border: '2px solid gray'},
    buttonContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    cancelButton: {
        width: '140px',
        backgroundColor: '#F0F0F0',
        color: color.black,
        outline: color.gray,
        fontWeight: '400',
        fontSize: '14px',
        textTransform: 'capitalize',
        margin: '8px',
        padding: '8px 20px',
        letterSpacing: '1px',
        border: 'none',
        borderRadius: '1rem',
        '&:hover': {
            backgroundColor: color.lightGray
        }
    },
    saveButton: {
        width: '150px',
        color: color.white,
        backgroundColor: color.buttonBg,
        fontWeight: '400',
        fontSize: '14px',
        textTransform: 'capitalize',
        letterSpacing: '1px',
        margin: '8px',
        padding: '8px 20px',
        border: 'none',
        borderRadius: '1rem',
        '&:hover': {
            backgroundColor: color.hoverButtonBg
        }
    },
    marker: {
        display: ' block',
        border: 'none',
        borderRadius: '50%',
        cursor: 'pointer',
        padding: 0
    },
    spinnerStyle: {
        color: color.white,
        width: '24px',
        height: '24px'
    },
    additionalContentDescription: {
        marginTop: '1rem',
        marginBottom: '1rem',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#999A9C'
    },
    noFileLabel: {
        color: color.white,
        fontSize: '16px',
        position: 'relative',
        top: '45%',
        left: '32%',
        letterSpacing: '1px',
        cursor: 'pointer',
        '&:hover': {
            color: color.gray
        }
    }
}))

export default NewEditTrail
