import jwt_decode from 'jwt-decode'

export const validate = () => {
	const decodedToken = jwt_decode(localStorage.getItem('auth_token'))
	const currentDate = new Date()

	if (decodedToken.exp * 1000 < currentDate.getTime()) {
		return false
	} else {
		return true
	}
}
