/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useEffect, useState} from 'react'
import {color, toastStyle} from '../../assets/css/commonStyle'
import {toast} from 'react-toastify'
import {
	Button,
	Grid,
	makeStyles,
	Modal,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	TextField,
	Typography,
	Select
} from '@material-ui/core'
import {MDBSpinner} from 'mdb-react-ui-kit'
import DefaultImage from '../../assets/images/default-item.jpeg'
import {aws_url, base_url} from '../../config/config'
import CloudIcon from '../../assets/svg/cloud_upload.svg'
import {validate} from "./validate";
import axios from "axios";
const errorMessage = 'Invalid image type. Please try again.'

const AddSettingModal = ({openCB, closeCB, updateData, modalTitle, addActionCB, updateActionCB, buttonSpinner, callerScreen}) => {
	const classes = useStyles()
	const [name, setName] = useState('')
	const [title, setTitle] = useState('')
	const [url, setUrl] = useState('')
	const [order, setOrder] = useState('')
	const [icon, setIcon] = useState('')
	const [active, setActive] = useState(false)
	const [iconClick, setIconClick] = useState(false)
	const [newImage, setNewImage] = useState(false)

	const [keyword, setKeyword] = useState('')
	const [keywords, setKeywords] = useState({})
	const [categoryKeywords, setCategoryKeywords] = useState([])

	useEffect(() => {
		if (updateData !== undefined && updateData !== '') {
			if (updateData.name) {
				setName(updateData.name)
			}
			if (updateData.icon) {
				setIcon(updateData.icon)
			}
			if (updateData.title) {
				setTitle(updateData.title)
			}
			if (updateData.order) {
				setOrder(updateData.order)
			}
			if (updateData.url) {
				setUrl(updateData.url)
			}
			if (updateData.order) {
				setOrder(updateData.order)
			}

			if(updateData.keywords) {
				setCategoryKeywords(updateData.keywords);
			}

			setActive(updateData.enabled)
		}
	}, [updateData])

	useEffect(() => {
		viewKeywords()
	}, [])

	const validateImage = (arr) => {
		let isImage = false
		const allowedTypes = ['jpg', 'jpeg', 'png', 'svg', 'webp', 'gif']
		for (let i = 1; i < allowedTypes.length; i++) {
			if (arr.includes(allowedTypes[i])) {
				isImage = true
			}
		}
		return isImage
	}

	const handleCatIcon = ({target}) => {
		const reader = new FileReader()
		if (target.files[0]) {
			reader.readAsDataURL(target.files[0])
		}
		reader.onload = () => {
			if (reader.readyState === 2) {
				const base64 = reader.result
				const isImage = validateImage(base64.substring(0, 30))
				if (isImage) {
					setIcon(base64)
					setNewImage(true)
					setIconClick(false)
				} else {
					toast.error(errorMessage, {position: toastStyle.position, autoClose: toastStyle.closeDuration})
				}
			}
		}
	}

	const viewKeywords = async () => {
		await axios
			.get(`${base_url}/admin/keywords/view_keywords`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('auth_token')}`
				}
			})
			.then((response) => {
				if (response.data.success) {
					setKeywords(response.data.data.keywords)
				}
			})
			.catch(function (error) {
				console.log("error" + JSON.stringify(error));
				toast.error('Something went wrong. Please try again later.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				return error
			})
	}


	const handleOldIconClick = async () => {
		setIconClick(true)
	}

	const updateAction = () => {
		console.log(callerScreen);
		if (callerScreen === 'legend' || callerScreen === 'asset_category') {
			const data = {
				name: name,
				order: order,
				icon: icon,
				active: active,
				keywords: categoryKeywords
			}
			updateActionCB(data)
		} else if (callerScreen === 'link') {
			const data = {
				title: title,
				url: url,
				order: order,
				active: active,
				keywords: categoryKeywords
			}
			updateActionCB(data)
		}
	}

	const addAction = () => {
		if (callerScreen === 'legend' || callerScreen === 'asset_category') {
			const data = {
				name: name,
				icon: icon,
				order: order,
				active: active,
				keywords: categoryKeywords
			}
			addActionCB(data)
		} else if (callerScreen === 'link') {
			const data = {
				title: title,
				url: url,
				order: order,
				active: active,
				keywords: categoryKeywords
			}
			addActionCB(data)
		}
	}

	const addCategoryKeywords = (data) => {

		if(!categoryKeywords.includes(data)) {
			let newData = categoryKeywords;
			newData.push(data);
			setCategoryKeywords(categoryKeywords);
			console.log(categoryKeywords);
		} else {
			console.log("Keyword already added.")
		}
	}

	return (
		<div className={classes.modal}>
			<Modal open={openCB} onClose={closeCB} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
				<div className={classes.paper}>
					<Card>
						<CardHeader title={<h3>{modalTitle}</h3>} className={classes.cardHead} />
						<CardContent>
							{addActionCB.name === 'addLink' || updateActionCB.name === 'updateLink' || callerScreen === 'link'? (
								<div>
									<Typography variant="body1" className={classes.textLabel}>
										Name
									</Typography>
									<TextField type="text" size="small" className={classes.textField} value={title} variant="outlined" label="Link Title" fullWidth onChange={(e) => setTitle(e.target.value)} />
									<Typography variant="body1" className={classes.textLabel}>
										Email
									</Typography>
									<TextField type="text" size="small" className={classes.textField} value={url} variant="outlined" label="Email" fullWidth onChange={(e) => setUrl(e.target.value)} />
									<Typography variant="body1" className={classes.textLabel}>
										Link Order
									</Typography>
									<input type="number" size="small" className={classes.textField} value={order} variant="outlined" label="Link Order" fullWidth onChange={(e) => setOrder(e.target.value)} />

									<Typography variant="body1" className={classes.textLabel}>
									</Typography>
									<label className="switch">
										<input type="checkbox" onChange={(e) => setActive(e.target.checked)} checked={active} />
										<span className={`slider round`}></span>
										<Typography variant="body1" className={classes.activeTextLabel}>
											Active
										</Typography>
									</label>
								</div>
							) : (
								<div>
									<Typography variant="body1" className={classes.textLabel}>
										Name
									</Typography>
									<TextField type="text" size="small" className={classes.textField} value={name} variant="outlined" label="Name" fullWidth onChange={(e) => setName(e.target.value)} />
									<Typography variant="body1" className={classes.textLabel}>
										Order
									</Typography>
									<TextField type="text" size="small" className={classes.textField} value={order} variant="outlined" label="Order" fullWidth onChange={(e) => setOrder(e.target.value)} />
									<Grid container spacing={2}>
										<Grid item xs={12} sm={12} md={12} lg={12} className={classes.iconContainer}>
											<Typography variant="body1" className={classes.textLabel}>
												Icon
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} className={`${iconClick ? '' : classes.hide}`}>
											<div htmlFor="upload3" className={classes.uploadOptionalImagesDashed}>
												<label htmlFor="upload3" className={classes.uploadLabel} aria-label="upload picture">
													<img src={`${CloudIcon}`} />
													<br />
													Drop your file here or <span style={{color: color.seaGreen, marginLeft: '0.3rem'}}>browse</span>
												</label>
												<input type="file" size="small" className={classes.uploadFile} id="upload3" draggable="true" onChange={handleCatIcon} />
											</div>
										</Grid>

										<Grid item className={classes.imageContainer} xs={2} sm={2} md={2} lg={2}>
											<Button variant="outlined" className={classes.image} onClick={() => handleOldIconClick()}>
												<img src={icon ? (newImage ? icon : `${aws_url}/${icon}`) : DefaultImage} className={`${iconClick ? classes.hide : classes.iconImage}`} />
											</Button>
										</Grid>
									</Grid>
									<label className="switch">
										<input type="checkbox" onChange={(e) => setActive(e.target.checked)} checked={active} />
										<span className={`slider round`}></span>
										<Typography variant="body1" className={classes.activeTextLabel}>
											Active
										</Typography>
									</label>

									<Typography variant="body1" className={classes.textLabel}>
										Keywords
									</Typography>

									<Select
										name="keywords"
										id="type"
										MenuProps={{
											anchorOrigin: {
												vertical: 'bottom',
												horizontal: 'left'
											},
											transformOrigin: {
												vertical: 'top',
												horizontal: 'left'
											},
											getContentAnchorEl: null
										}}
										variant="outlined"
										value={keyword}
										className={classes.selectBox}
										onChange={(e) => addCategoryKeywords(e.target.value)}>
										<option value="no_value" className={classes.option}>
											Keyword
										</option>
										{keywords.length > 0 &&
										keywords.map((item, index) => {
											return (
												<option key={index} value={item.keyword} className={classes.option}>
													{item.keyword}
												</option>
											)
										})}
									</Select>

									<div>
										{categoryKeywords.length > 0 &&
										categoryKeywords.map((item, index) => {
											console.log("Entered");
											return (
												<li> {item} </li>
											)
										})}
									</div>


								</div>
							)}
						</CardContent>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<CardActions className={classes.cardActions}>
								<Grid item className={classes.fieldsContainer} xs={12} sm={12}>
									<Button variant="outlined" className={classes.cancelButton} size="small" onClick={closeCB}>
										Cancel
									</Button>
									{updateData !== undefined ? (
										<Button variant="contained" className={classes.addButton} size="small" onClick={updateAction}>
											{buttonSpinner ? <MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner> : <label className={classes.buttonLabel}>Save Changes</label>}
										</Button>
									) : (
										<Button variant="contained" className={classes.addButton} size="small" onClick={addAction}>
											{buttonSpinner ? <MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner> : <label className={classes.buttonLabel}>Save Changes</label>}
										</Button>
									)}
								</Grid>
							</CardActions>
						</Grid>
					</Card>
				</div>
			</Modal>
		</div>
	)
}

const useStyles = makeStyles((theme) => ({
	paper: {
		textAlign: 'left',
		color: color.black,
		width: '40%',
		position: 'absolute',
		left: '0',
		right: '0',
		margin: 'auto',
		borderRadius: '5px',
		marginTop: '10px',
		[theme.breakpoints.down('md')]: {
			width: '80%'
		},
		[theme.breakpoints.up('md')]: {
			width: '40%'
		}
	},
	textLabel: {
		marginTop: '15px',
		color: color.textColor,
		fontSize: '16px',
		fontWeight: '700'
	},
	activeTextLabel: {
		color: color.textColor,
		fontSize: '16px',
		fontWeight: '700',
		position: 'absolute',
		top: '0.24rem',
		left: '5rem'
	},
	textField: {
		marginTop: '15px',
		border: `1px solid ${color.lightGray} !important`,
		borderRadius: '5px !important'
	},
	fieldsContainer: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	fileUploadContainer: {},
	fileUploader: {},
	imageContainer: {
		height: '5rem'
	},
	image: {
		position: 'relative',
		top: 0,
		left: 0,
		padding: 0,
		borderRadius: '12px',
		border: 'none',
		backgroundColor: color.green
	},
	iconImage: {
		width: '64px',
		height: '44px',
		borderRadius: '12px',
		margin: 0
	},
	label: {
		color: color.primary
	},
	cardHead: {
		backgroundColor: color.white,
		color: color.black
	},
	cardActions: {
		padding: '11px 10px 15px 10px',
		justifyContent: 'right'
	},
	cancelButton: {
		width: '140px',
		backgroundColor: '#F0F0F0',
		color: color.black,
		outline: color.gray,
		fontWeight: '400',
		fontSize: '14px',
		textTransform: 'capitalize',
		margin: '8px',
		padding: '8px 20px',
		letterSpacing: '1px',
		border: 'none',
		borderRadius: '1rem',
		'&:hover': {
			backgroundColor: color.lightGray
		}
	},
	buttonLabel: {margin: 0},
	addButton: {
		width: '150px',
		color: color.white,
		backgroundColor: color.buttonBg,
		fontWeight: '400',
		fontSize: '14px',
		textTransform: 'capitalize',
		letterSpacing: '1px',
		margin: '8px',
		padding: '8px 20px',
		border: 'none',
		borderRadius: '1rem',
		'&:hover': {
			backgroundColor: color.hoverButtonBg
		}
	},
	spinnerStyle: {
		color: color.white,
		width: '12px',
		height: '12px'
	},
	hide: {display: 'none'},
	uploadFile: {display: 'none'},
	iconContainer: {marginTop: '1.6rem'},
	uploadOptionalImagesDashed: {
		width: '100%',
		border: '2px dashed #00A850',
		borderRadius: '12px !important',
		paddingTop: '2rem',
		paddingBottom: '2rem',
		display: 'flex-inline',
		justifyContent: 'center',
		textAlign: 'center',
		backgroundColor: color.ultraLightGreen,
		cursor: 'pointer'
	},
	uploadLabel: {cursor: 'pointer'}
}))
export default AddSettingModal
