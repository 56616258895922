/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {makeStyles, Typography, Button, Box, Grid, Avatar} from '@material-ui/core'
import {AddCircle, Edit, Save} from '@material-ui/icons'
import {color, toastStyle} from '../assets/css/commonStyle'
import {toast} from 'react-toastify'
import {MDBDataTableV5} from 'mdbreact'
import {MDBSpinner} from 'mdb-react-ui-kit'
import {aws_url, base_url} from '../config/config'
import AddSettingModal from '../components/common/AddSettingsModal'
import {Editor} from 'react-draft-wysiwyg'
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Links from './LinksSubTab'
import {connect} from 'react-redux'
import {storeLogin} from '../redux/actions/actions'
import {validate} from '../components/common/validate'
import SessionExpiredModal from '../components/common/SessionExpireModal'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const PopupTab = (props) => {
	const classes = useStyles()

	const [updateData, setUpdateData] = useState(undefined)
	const [updateId, setUpdateId] = useState('')
	const [openModal, setOpenModal] = useState(false)
	const [name, setName] = useState('')
	const [order, setOrder] = useState(0)
	const [icon, setIcon] = useState('')
	const [active, setActive] = useState(false)
	const [buttonSpinner, setButtonSpinner] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [spinner, setSpinner] = useState(false)
	const [session, setSession] = useState(true)

	const [editorState, setEditorState] = useState(EditorState.createEmpty())

	const [legends, setLegends] = useState({
		columns: [
			{
				label: 'Name',
				field: 'name',
				width: 270,
				attributes: {'aria-controls': 'DataTable', 'aria-label': 'Name'}
			},
			{
				label: 'Order',
        field: 'order',
				attributes: {'aria-controls': 'DataTable', 'aria-label': 'Order'}
			},
			{
				label: 'Icon',
				field: 'file'
			},
			{label: 'Enabled', field: 'enabled', type: 'checkbox'},
			{label: '', field: 'edit', width: 200, sort: String('disabled')}
		],
		rows: []
	})

	useEffect(() => {
		viewLegends(currentPage)
		getContent()
	}, [])

	const handleEdit = (data) => {
		setUpdateData(data)
		setUpdateId(data._id)
		setName(data.name)
		setOrder(data.order)
		setIcon(data.icon)
		setActive(data.enabled)
		setOpenModal(true)
	}

	const handleAddNew = () => {
		setOpenModal(true)
	}

	const cancel = () => {
		setOpenModal(false)
		setUpdateData(undefined)
	}

	const handleCloseSessionModal = () => {
		viewLegends()
		setSession(true)
	}

	const viewLegends = async (page) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			setSpinner(true)
			await axios
				.get(`${base_url}/admin/settings/legends/view_legends`)
				.then((response) => {
					if (response.data.success) {
						setSpinner(false)
						setLegends((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						//sort data by order
						response.data.data.legends.sort((a, b) => {
							return a.order - b.order;
						})
						response.data.data.legends.map((data) => {
							createTable(data)
						})
					} else setSpinner(false)
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					setSpinner(false)

					return error
				})
		}
	}

	const getContent = async () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			await axios
				.get(`${base_url}/admin/settings/sub_setting/view_sub_settings`)
				.then((response) => {
					if (response.data.success) {
						if (response.data.data.subsettings.length > 0 && response.data.data.subsettings[0].content !== undefined) {
							setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.data.subsettings[0].content))))
						}
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					return error
				})
		}
	}

	const convertContent = (state) => {
		return JSON.stringify(convertToRaw(state.getCurrentContent()))
	}

	const setContent = async () => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			let convertedContent = convertContent(editorState)
			await axios
				.post(
					`${base_url}/admin/settings/sub_setting/add`,
					{
						content: convertedContent
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('auth_token')}`
						}
					}
				)
				.then((response) => {
					if (response.data.success) {
						toast.success('Content has been updated successfully.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						setButtonSpinner(false)
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					setButtonSpinner(false)
					return error
				})
		}
	}

	const updateLegend = async (data) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			if (data.name === '') {
				toast.error('Legend name is required.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else {
				setButtonSpinner(true)
				await axios
					.post(
						`${base_url}/admin/settings/legends/update`,
						{
							id: updateId,
							name: data.name,
							order: data.order,
							icon: data.icon,
							enabled: data.active
						},
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem('auth_token')}`
							}
						}
					)
					.then((response) => {
						if (response.data.success) {
							toast.success('Legend updated successfully.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
							setOpenModal(false)
							setButtonSpinner(false)
							viewLegends()
						} else {
							toast.error('Something went wrong. Please try again later', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
						}
					})
					.catch(function (error) {
						toast.error('Something went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						return error
					})
			}
		}
	}

	const handleEnable = async (row) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			await axios
				.post(
					`${base_url}/admin/settings/legends/update`,
					{
						...row,
						id: row._id,
						enabled: !row.enabled
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('auth_token')}`
						}
					}
				)
				.then((response) => {
					if (response.data.success) {
						toast.success('Enable status updated successfully.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						viewLegends()
					} else {
						toast.error('Soemthing went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					return error
				})
		}
	}

	const addLegend = async (data) => {
		if (!validate()) {
			props.loginHandler(false)
			setSession(false)
		} else {
			if (data.name.length === 0) {
				toast.error('Legend name is required.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else {
				setButtonSpinner(true)
				await axios
					.post(
						`${base_url}/admin/settings/legends/add`,
						{
							name: data.name,
							order: data.order,
							icon: data.icon,
							enabled: data.active
						},
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem('auth_token')}`
							}
						}
					)
					.then((response) => {
						if (response.data.success) {
							toast.success('Legend added successfully.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
							setOpenModal(false)
							setButtonSpinner(false)
							viewLegends()
						} else {
							toast.error('Legend already exist.', {
								position: toastStyle.position,
								autoClose: toastStyle.closeDuration
							})
						}
						setButtonSpinner(false)
					})
					.catch(function (error) {
						toast.error('Something went wrong. Please try again later.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
						return error
					})
			}
		}
	}

	const createTable = (row) => {
		let _obj = {
			name: row.name,
			order: row.order,
			file: <img src={`${aws_url}/${row.icon}`} className={classes.imgTable} />,
			enabled: (
				<label className="switch">
					<input type="checkbox" onChange={() => handleEnable(row)} checked={row.enabled} />
					<span className={`slider round`}></span>
				</label>
			),
			edit: (
				<Avatar variant="square" className={classes.editButton}>
					<Edit
						className="editIcon"
						onClick={() => {
							handleEdit(row)
						}}
					/>
				</Avatar>
			)
		}
		setLegends((preValue) => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	let wordCount = 0
	if (convertToRaw(editorState.getCurrentContent())?.blocks?.length > 0) {
		for (const block of convertToRaw(editorState.getCurrentContent())?.blocks) {
			wordCount += block.text.length
		}
	}

	return (
		<div className={classes.root}>
			{session ? (
				<div>
					{openModal === false ? (
						<div>
							<div className={classes.contentContainer}>
								<Grid container>
									<Grid item xs={12} sm={12} md={11} lg={11}>
										<Typography variant="body1" className={classes.tableHeading}>
											Legend
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={1} lg={1}>
										<Button variant="contained" className={classes.addButton} startIcon={<AddCircle style={{fontSize: '16px', background: 'none'}} className="addNewIconBlack" />} size="small" onClick={() => handleAddNew()}>
											<Box style={{paddingTop: '1px'}}>Add</Box>
										</Button>
									</Grid>
								</Grid>

								{spinner ? (
									<div className={classes.Flex}>
										<MDBSpinner role="status" className={classes.spinnerStyle}></MDBSpinner>
									</div>
								) : (
									<div id="legends">
										<MDBDataTableV5 className={classes.table} hover entries={25} data={legends} fullPagination={false} paging={false} info={false} searchTop={false} searchBottom={false} />
									</div>
								)}
							</div>
							<Links />
							<div className={classes.additionalContentContainer}>
								<Typography variant="body1" className={classes.additionalContentHeading}>
									Additional Content
								</Typography>
								<Typography variant="body1" className={classes.additionalContentDescription}>
									{wordCount <= 1500 ? wordCount : 1500} of 1500 words
								</Typography>
								<div className={classes.saveBtnContainer}>
									<Editor editorState={editorState} value={editorState} toolbarClassName="toolbarClassName"  wrapperClassName={classes.wrapperClass}  editorClassName={classes.editorClass} onEditorStateChange={setEditorState}/>

									<Button variant="outlined" className={classes.saveButton} startIcon={<Save style={{fontSize: '14px'}} />} size="small" onClick={setContent}>
										Save
									</Button>
								</div>
							</div>
						</div>
					) : (
						<AddSettingModal openCB={openModal} closeCB={cancel} modalTitle="New/Edit Legend" updateData={updateData} addActionCB={addLegend} updateActionCB={updateLegend} buttonSpinner={buttonSpinner} callerScreen="legend" />
					)}{' '}
				</div>
			) : (
				<SessionExpiredModal closeCB={handleCloseSessionModal} />
			)}
		</div>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		height: '100%',
		flexGrow: 1,
		backgroundColor: color.pagesBg,
		padding: '2rem'
	},
	tableHeading: {
		marginTop: '1rem',
		fontSize: '24px',
		fontWeight: '700'
	},
	editButton: {
		fontSize: '11px',
		backgroundColor: 'transparent',
		color: color.white
	},
	addButton: {
		position: 'relative',
		top: '20px',
		padding: '8px 20px',
		backgroundColor: color.cancelBtnBg,
		color: color.textColor,
		fontSize: '16px',
		fontWeight: '700',
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: color.hoverCancel
		}
	},
	wrapperClass: {
		border: `1px solid ${color.newBorder}`,
		borderRadius: '0.5rem',
		padding: '0.2rem'
	},
	toolbarClass: {
		display: 'none',
		border: `1px solid  ${color.newBorder}`
	},
	editorClass: {
		padding: '0.1rem',
		color: color.darkGray,
		height: '12rem'
	},
	saveBtnContainer: {textAlign: 'right'},
	saveButton: {
		width: '8rem',
		marginTop: '1rem',
		backgroundColor: color.buttonBg,
		color: color.white,
		fontSize: '12px',
		textTransform: 'capitalize',
		padding: '0.4rem 0.8rem',
		'&:hover': {
			backgroundColor: color.hoverButtonBg
		}
	},
	Flex: {
		display: 'flex',
		justifyContent: 'center'
	},
	spinnerStyle: {
		color: color.white,
		width: '12px',
		height: '12px'
	},
	contentContainer: {
		backgroundColor: color.white,
		borderRadius: '12px',
		padding: '14px 18px'
	},
	additionalContentContainer: {
		marginTop: '2rem',
		backgroundColor: color.white,
		borderRadius: '12px',
		padding: '32px',
		gap: '40px'
	},
	additionalContentHeading: {
		marginTop: '1rem',
		fontSize: '16px',
		fontWeight: '700'
	},
	additionalContentDescription: {
		marginTop: '1rem',
		marginBottom: '1rem',
		fontStyle: 'normal',
		fontSize: '16px',
		lineHeight: '24px',
		color: '#999A9C'
	},
	imgTable: {
		width: '32px',
		height: '32px'
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => ({
	loginHandler: (data) => dispatch(storeLogin(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PopupTab)
