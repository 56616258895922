/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Button, Box} from '@material-ui/core'
import {ArrowBack, ArrowForward} from '@material-ui/icons'
import {color} from '../../assets/css/commonStyle'

function Pagination({viewCB, len, page}) {
	const classes = useStyles()
	const [nextPageToken, setNextPageToken] = useState('')
	const entries = 25

	useEffect(() => {
		setNextPageToken(page)
	}, [])

	const incrementPage = async () => {
		setNextPageToken(1 + nextPageToken)
		viewCB(1 + nextPageToken)
	}

	const decrementPage = async () => {
		setNextPageToken(nextPageToken - 1)
		viewCB(nextPageToken - 1)
	}

	return (
		<>
			<div className={classes.pagination}>
				<Box>
					{nextPageToken <= 1 && len < entries ? (
						<></>
					) : nextPageToken <= 1 ? (
						<>
							<Button variant="outlined" onClick={() => incrementPage()} className={classes.paginateButton} startIcon="">
								<ArrowForward className={classes.arrows} />
							</Button>
						</>
					) : nextPageToken > 1 && len < entries ? (
						<>
							<Button variant="outlined" onClick={decrementPage} className={classes.paginateButton} startIcon="">
								<ArrowBack className={classes.arrows} />
							</Button>
						</>
					) : (
						<>
							<Button variant="outlined" onClick={decrementPage} className={classes.paginateButton} startIcon="">
								<ArrowBack className={classes.arrows} />
							</Button>
							<Button variant="outlined" onClick={() => incrementPage()} className={classes.paginateButton} startIcon="">
								<ArrowForward className={classes.arrows} />
							</Button>
						</>
					)}
				</Box>
			</div>
		</>
	)
}

const useStyles = makeStyles((theme) => ({
	pagination: {
		color: color.gray,
		fontSize: '14px',
		display: 'flex',
		justifyContent: 'center'
	},
	paginateButton: {
		margin: '8px',
		backgroundColor: color.buttonBg,
		color: color.black,
		fontSize: '16px',
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: color.hoverButtonBg,
			color: color.white
		}
	},
	arrows: {color: color.white}
}))
export default Pagination
