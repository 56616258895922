/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'
import {Container, Button, makeStyles, TextField, Typography} from '@material-ui/core'
import {color, toastStyle} from '../assets/css/commonStyle'
import {base_url} from '../config/config'
import {connect} from 'react-redux'
import {storeUser, storeLngLat} from '../redux/actions/actions'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const Login = (props) => {
	const classes = useStyles()
	let _history = useHistory()

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const validateEmail = (email) => {
		let validFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		if (email.match(validFormat)) return true
		else return false
	}

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(function (position) {
			props.setUserLngLat([position.coords.longitude, position.coords.latitude])
		})
	})

	const loginUser = () => {
		const validEmail = validateEmail(email)
		if (email === '' || password === '') {
			toast.error('Please enter email and password for login to the system', {
				position: toastStyle.position,
				autoClose: toastStyle.closeDuration
			})
		} else if (!validEmail) {
			toast.error('Invalid email. Please try again', {
				position: toastStyle.position,
				autoClose: toastStyle.closeDuration
			})
		} else if (password.length < 8) {
			toast.error('Password must not be less than 8 characters. Please try again', {
				position: toastStyle.position,
				autoClose: toastStyle.closeDuration
			})
		} else {
			window.localStorage.removeItem('auth_token')
			window.localStorage.clear()
			doLogin()
		}
	}

	const forgotPassword = () => {
		_history.push('/forgot_password')
		props.gotoForgetPage()
	}

	const doLogin = () => {
		axios
			.post(`${base_url}/admin/admin_login`, {
				email: email,
				password: password
			})
			.then((response) => {
				if (response.data.success) {
					toast.success('Login Successfully.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					localStorage.setItem('auth_token', response.data.data.admin.token)
					props.onLoginSuccess(response.data.data.admin)

					_history.push('/')
				} else {
					toast.error('There is a problem logging you in. Please try again, or contact an administrator.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
				}
			})
			.catch(function (error) {
				toast.error('There is a problem logging you in. Please try again, or contact an administrator.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				console.log(error)
			})
	}



	return (
		<Container maxWidth="xs">
			<Typography variant="h4" className={classes.heading}>
				Login
			</Typography>
			<TextField type="email" size="small" className={classes.textField} variant="outlined" label="Email" fullWidth onChange={(e) => setEmail(e.target.value)} onKeyPress={(e) => { if (e.key === 'Enter') { loginUser() }}}/>
			<TextField type="password" size="small" className={classes.textField} variant="outlined" label="Password" fullWidth onChange={(e) => setPassword(e.target.value)} onKeyPress={(e) => { if (e.key === 'Enter') { loginUser() }}} />
			<Button size="medium" onClick={forgotPassword} className={classes.forgetButton}>
				Forgot password?
			</Button>
			<Button size="medium" className={classes.loginButton} onClick={loginUser}>
				Login
			</Button>
		</Container>
	)
}

const useStyles = makeStyles(() => ({
	heading: {
		fontWeight: '400',
		fontSize: '36px',
		color: color.black,
		padding: '10px 0px 0px 0px',
		textAlign: 'center',
		marginTop: '60px'
	},
	textField: {
		marginTop: '40px',
		border: `1px solid ${color.lightGray} !important`,
		borderRadius: '5px !important',
		backgroundColor: color.lightGray
	},
	forgetButton: {
		marginTop: '40px',
		color: color.primary,
		padding: '12px'
	},
	loginButton: {
		marginTop: '40px',
		backgroundColor: color.seaGreen,
		color: color.white,
		padding: '12px',
		width: '120px',
		float: 'right',
		border: '0.5px solid black',
		'&:hover': {
			backgroundColor: color.darkGreen
		}
	}
}))

const mapStateToProps = (state) => ({
	store: state.OKCStore
})

const mapDispatchToProps = (dispatch) => {
	return {
		onLoginSuccess: (data) => {
			dispatch(storeUser(data))
		},
		setUserLngLat: (data) => {
			dispatch(storeLngLat(data))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
