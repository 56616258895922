/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router'
import {Container, Button, makeStyles, TextField, Typography} from '@material-ui/core'
import {base_url} from '../config/config'
import {color, toastStyle} from '../assets/css/commonStyle'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const ForgotPassword = () => {
	let _history = useHistory()
	const classes = useStyles()
	const [email, setEmail] = useState('')
	const [clicked, setClicked] = useState(false)
	const message = 'An email containing a new password has been sent to your email address. Please check your inbox.'

	const goToLogin = () => {
		_history.push('/')
	}

	const forgetUser = () => {
		if (email === '') {
			toast.error('Please enter a valid email address for password reset.', {
				position: toastStyle.position,
				autoClose: toastStyle.closeDuration
			})
		} else {
			doForgotPassword()
		}
	}

	window.addEventListener('popstate', () => {
		_history.push('/')
		window.location.reload(false)
		localStorage.removeItem('auth_token')
		localStorage.clear()
	})

	const doForgotPassword = async () => {
		await axios
			.post(`${base_url}/admin/forgot_password`, {
				email: email
			})
			.then((response) => {
				if (response.data.status) {
					toast.success('Email sent successfully.', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					setClicked(true)
					localStorage.removeItem('auth_token')
					localStorage.clear()
				} else {
					if (response.data.message === 'Email not found') {
						toast.error('Email not found. Please try again, or contact an administrator.', {
							position: toastStyle.position,
							autoClose: toastStyle.closeDuration
						})
					}
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				console.log(error)
			})
	}

	return (
		<Container maxWidth="xs">
			<Typography variant="h4" className={classes.heading}>
				Forgot Password
			</Typography>
			{clicked ? (
				<div className={classes.div}>
					<Typography variant="body1" className={classes.para}>
						{message}
					</Typography>
					<Button size="medium" className={classes.sendButton} onClick={goToLogin}>
						Proceed to Login
					</Button>
				</div>
			) : (
				<div className={classes.div}>
					<TextField type="email" size="small" className={classes.textField} variant="outlined" label="Email" fullWidth onChange={(e) => setEmail(e.target.value)} />
					<Button size="medium" className={classes.sendButton} onClick={forgetUser}>
						Send Reset Request
					</Button>
					<br />
					<a classNeme={classes.backLink} href="/">
						Back to Login
					</a>
				</div>
			)}
		</Container>
	)
}

const useStyles = makeStyles(() => ({
	heading: {
		fontWeight: '400',
		fontSize: '36px',
		color: color.black,
		padding: '10px 0px 0px 0px',
		textAlign: 'center',
		marginTop: '60px'
	},
	textField: {
		marginTop: '40px',
		border: `1px solid ${color.lightGray} !important`,
		borderRadius: '5px !important',
		backgroundColor: color.lightGray
	},
	div: {textAlign: 'center'},
	para: {
		fontSize: '13px',
		color: color.black,
		display: 'block',
		marginTop: '40px',
		fontWeight: 'none'
	},
	sendButton: {
		marginTop: '40px',
		backgroundColor: color.seaGreen,
		color: color.white,
		padding: '12px',
		width: '200px',
		border: '0.5px solid black',
		'&:hover': {
			backgroundColor: color.darkGreen
		}
	},
	backLink: {
		color: color.primary,
		textTransform: 'uppercase',
		marginTop: '4rem',
		'&:hover': {
			color: color.hoverPrimary
		}
	}
}))

export default ForgotPassword
